import {Menu, MenuProps} from "antd";
import React, {useEffect, useState} from "react";
import {Content} from "antd/es/layout/layout";
import BillingAddress from "./Adresses/BillingAddress";
import DeliveryAddress from "./Adresses/DeliveryAddress";
import ShippingAddress from "./Adresses/ShippingAddress";

export default function Addresses() {

    const items: MenuProps['items'] = [
        {
            label: 'Adresse de Facturation',
            key: 'billing',
        },
        {
            label: 'Adresse de Livraison',
            key: 'delivery',
        },
        localStorage.getItem('role') !== "buyer" ? {
            label: 'Adresse d\'Expédition',
            key: 'shipping',
        } : {
            label: '',
            key: ''
        }
    ];

    const itemsPhone: MenuProps['items'] = [
        {
            label: 'Facturation',
            key: 'billing',
        },
        {
            label: 'Livraison',
            key: 'delivery',
        },
        {
            label: 'Expédition',
            key: 'shipping',
        },
    ];

    const [content, setCurrent] = useState('billing');

    const onClick: MenuProps['onClick'] = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            {windowWidth < 768 ? (
                <div>
                    <Menu onClick={onClick} selectedKeys={[content]} mode="horizontal" items={itemsPhone}/>
                    {
                        content === "billing" ? (
                            <Content
                                style={{
                                    background: "#FFFFFF",
                                    marginTop: '15px'
                                }}
                            >
                                <BillingAddress/>
                            </Content>
                        ) : content === "delivery" ? (
                            <Content
                                style={{
                                    background: "#FFFFFF",
                                    marginTop: '15px'
                                }}
                            >
                                <DeliveryAddress/>
                            </Content>
                        ) : content === "shipping" && localStorage.getItem('role') !== "buyer" ? (
                            <Content
                                style={{
                                    background: "#FFFFFF",
                                    marginTop: '15px'
                                }}
                            >
                                <ShippingAddress/>
                            </Content>
                        ) : null
                    }
                </div>
            ) : (
                <div>
                    <Menu onClick={onClick} selectedKeys={[content]} mode="horizontal" items={items}/>
                    {
                        content === "billing" ? (
                            <Content
                                style={{
                                    background: "#FFFFFF",
                                    marginTop: '15px'
                                }}
                            >
                                <BillingAddress/>
                            </Content>
                        ) : content === "delivery" ? (
                            <Content
                                style={{
                                    background: "#FFFFFF",
                                    marginTop: '15px'
                                }}
                            >
                                <DeliveryAddress/>
                            </Content>
                        ) : content === "shipping" && localStorage.getItem('role') !== "buyer" ? (

                            <Content
                                style={{
                                    background: "#FFFFFF",
                                    marginTop: '15px'
                                }}
                            >
                                <ShippingAddress/>
                            </Content>
                        ) : null
                    }
                </div>
            )
            }
        </div>
    )
}