import React, {useEffect, useState} from 'react';
import {
    AntDesignOutlined,
    BankOutlined, BellFilled,
    BellOutlined,
    ExportOutlined,
    HomeOutlined,
    LeftOutlined,
    LogoutOutlined,
    MailOutlined,
    MenuFoldOutlined,
    MenuOutlined,
    MenuUnfoldOutlined,
    RightOutlined,
    SearchOutlined,
    SettingOutlined,
    ShopOutlined,
    ToolOutlined,
    TagsOutlined,
    UploadOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {Layout, Menu, Button, theme, Input, Space, Avatar, Row, Col, Drawer, Divider, Badge} from 'antd';
import {Home, Products, CreateProduct, Commands, BankView, Settings} from '../../../components/Dashboard'
import logo from '../../../img/poseidon_logo_finish-01.webp'
import {Link} from "react-router-dom";
import _default from "chart.js/dist/plugins/plugin.tooltip";
import logo2 from "../../../img/poseidon_logo_finish-03.webp";
import {GetCartCount} from "../../../components/Cart";
import CommandsAdmin from "../../../components/Dashboard/Admin/Commands";
import ProductsAdmin from "../../../components/Dashboard/Admin/Products";
import {useTranslation} from "react-i18next";
import {WebsiteSettings} from "../../../components/Dashboard/Admin/WebsiteSettings";
import {CompanyView} from "../../../components/Dashboard/Admin/CompanyView";

export const useDashboard = () => {
    const [selectedComponent, setSelectedComponent] = useState<string>('home');
    const handleItemClick = (component: string) => {
        setSelectedComponent(component);
        console.log(component)
    };

    const [content, setContent] = useState("1");

    const changeContent = (key: string) => {
        setContent(key);
    }

    const renderComponent = () => {
        switch (selectedComponent) {
            case 'home':
                return <Home/>;
            case 'products':
                return <ProductsAdmin/>;
            case 'createProduct':
                return <CreateProduct/>;
            case 'commands':
                return <Commands/>;
            case 'bankView':
                return <BankView/>;
            case 'settings':
                return <Settings/>;
            default:
                return null;
        }
    };


    return { handleItemClick };
};


const {Header, Sider, Content} = Layout;

const Admin: React.FC = () => {
    const {t} = useTranslation();

    if (!localStorage.getItem('token')) {
        window.location.href = "/login"
    }
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const [content, setContent] = useState("1");

    const changeContent = (key: string) => {
        setContent(key);
    }


    const colorHeaderVerticalBar = "#EAEAEA"
    const colorHeaderText = "#09154C"
    const colorHearderSearch = "rgba(0,0,0,0.0)"
    const colorHeaderButtonBg = "#7ED957"

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [count, setCount] = useState(0);

    useEffect(() => {
        const fetchCartCount = async () => {
            try {
                setCount(await GetCartCount());
            } catch (error) {
                // handle error
            }
        };

        fetchCartCount();
    }, [count]);

    const increase = () => {
        setCount(count + 1);
    };

    const decline = () => {
        let newCount = count - 1;
        if (newCount < 0) {
            newCount = 0;
        }
        setCount(newCount);
    };

    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const [selectedComponent, setSelectedComponent] = useState<string>('home');

   const handleItemClick = (component: string) => {
        setSelectedComponent(component);
        setOpen(false); // Close the drawer
    };

    const handleBadgeClick = () => {
        setSelectedComponent('settings');
    };

    const renderComponent = () => {
        switch (selectedComponent) {
            case 'home':
                return <Home/>;
            case 'products':
                return <ProductsAdmin/>;
            case 'createProduct':
                return <CreateProduct/>;
            case 'commands':
                return <Commands/>;
            case 'commandsAdmin':
                return <CommandsAdmin/>;
            case 'bankView':
                return <BankView/>;
            case 'settings':
                return <Settings/>;
            case 'website_settings':
                return <WebsiteSettings/>;
            case 'companies':
                return <CompanyView/>;
            default:
                return null;
        }
    };



    return (
        <div>
            <style>
                {`.anticon svg { display: block !important;}`}
            </style>
            {windowWidth < 1100 ? (
                <div style={{backgroundColor: 'white'}} className="header-container">
                    <style>
                        {`
                        .header-container img {
                                height: 50px; /* Adjust the height as needed */
                        }
                            
                        .header-container .anticon {
                            font-size: 14px; /* Adjust the font size as needed */
                        }
                        
                        @media (min-width: 320px) {
                            
                            .header-container .anticon {
                                font-size: 14px; /* Adjust the font size as needed */
                            }
                        }
                        
                        @media (min-width: 768px) {
                                                               
                            .header-container img {
                                height: 70px; /* Adjust the height as needed */
                            }
                            
                            .header-container .anticon {
                                font-size: 14px; /* Adjust the font size as needed */
                            }
                        }
                        
                        .gg-anchor {
                            box-sizing: border-box;
                            position: relative;
                            display: block;
                            transform: scale(var(--ggs,1));
                            width: 2px;
                            height: 14px;
                            background: currentColor;
                            border-radius: 8px
                        }
                        .gg-anchor::after,
                        .gg-anchor::before {
                            content: "";
                            display: block;
                            box-sizing: border-box;
                            position: absolute;
                            height: 6px;
                            border: 2px solid
                        }
                        .gg-anchor::after {
                            width: 12px;
                            border-bottom-left-radius: 20px;
                            border-bottom-right-radius: 20px;
                            border-top-color: transparent;
                            left: -5px;
                            bottom: 2px
                        }
                        .gg-anchor::before {
                            width: 6px;
                            border-radius: 8px;
                            top: -4px;
                            left: -2px
                        }
                      
            
                    `}
                    </style>
                    <Row>
                        <Col flex={1} style={{display: 'flex', alignItems: 'center', margin: 'auto 10px'}}>
                            <MenuOutlined onClick={showDrawer} style={{
                                backgroundColor: "white",
                                height: '100%',
                                width: '100%',
                                color: colorHeaderText
                            }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                            <Drawer title="Menu" style={{height: '100vh', color: colorHeaderText}} closable={true}
                                    onClose={onClose}
                                    open={open} placement={'top'}>
                                <Row style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}
                                     onClick={() => handleItemClick('home')}>
                                    <p style={{ display: 'inline-block', width: '92%' }}>{t('Accueil')}</p>
                                    <RightOutlined style={{ fontSize: '10px' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                </Row>
                                <Row style={{marginBottom: '15px', display: 'flex', alignItems: 'center'}}
                                     onClick={() => handleItemClick('website_settings')}>
                                    <p style={{display: 'inline-block', width: '92%'}}>{t('Paramètres du site')}</p>
                                    <RightOutlined style={{fontSize: '10px'}} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                </Row>

                                <Row style={{marginBottom: '15px', display: 'flex', alignItems: 'center'}}
                                     onClick={() => handleItemClick('products')}>
                                    <p style={{display: 'inline-block', width: '92%'}}>{t('Produits')}</p>
                                    <RightOutlined style={{fontSize: '10px'}} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                </Row>
                                <Row style={{marginBottom: '15px', display: 'flex', alignItems: 'center'}}
                                     onClick={() => handleItemClick('createProduct')}>
                                    <p style={{display: 'inline-block', width: '92%'}}>{t('Créer un produit')}</p>
                                    <RightOutlined style={{fontSize: '10px'}} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                </Row>
                                <Row style={{marginBottom: '15px', display: 'flex', alignItems: 'center'}}
                                     onClick={() => localStorage.getItem("role") === "admin" ? handleItemClick('commandsAdmin') :  handleItemClick('commands')}>
                                    <p style={{display: 'inline-block', width: '92%'}}>{t('Commandes')}</p>
                                    <RightOutlined style={{fontSize: '10px'}} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                </Row>
                                <Row style={{marginBottom: '15px', display: 'flex', alignItems: 'center'}}
                                     onClick={() => handleItemClick('bankView')}>
                                    <p style={{display: 'inline-block', width: '92%'}}>{t('Aperçu bancaire')}</p>
                                    <RightOutlined style={{fontSize: '10px'}} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                </Row>
                                <Row style={{marginBottom: '15px', display: 'flex', alignItems: 'center'}}
                                     onClick={() => handleItemClick('settings')}>
                                    <p style={{display: 'inline-block', width: '92%'}}>{t('Paramètres')}</p>
                                    <RightOutlined style={{fontSize: '10px'}} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                </Row>
                                <Row style={{marginBottom: '15px', display: 'flex', alignItems: 'center'}}>
                                    <a href={'/'} style={{display: 'inline-block', width: '92%', color: colorHeaderText}}>{t('Retour au site')}</a>
                                    <RightOutlined style={{fontSize: '10px'}} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                </Row>
                                <Row style={{marginBottom: '15px', display: 'flex', alignItems: 'center'}}>
                                    <a href={'/logout'} style={{display: 'inline-block', width: '92%', color: colorHeaderText}}>{t('Déconnexion')}</a>
                                    <RightOutlined style={{fontSize: '10px'}} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                </Row>
                            </Drawer>
                        </Col>
                        <Col flex={3} style={{display: 'flex', alignItems: 'center'}}>
                            <img src={logo2} style={{maxWidth: '100%'}}/>
                        </Col>
                        <Col flex={1} style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: 'auto 10px',
                            justifyContent: 'center'
                        }}>
                            <a href={"/logout"}>
                                <LogoutOutlined style={{
                                    backgroundColor: colorHeaderText,
                                    border: '1px solid' + colorHeaderText,
                                    borderRadius: '15px',
                                    padding: '10px',
                                    height: '100%',
                                    width: '100%',
                                    color: 'white'
                                }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                            </a>
                        </Col>
                        <Col flex={1} style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: 'auto 10px',
                            justifyContent: 'center'
                        }}>
                            <div
                                style={{ right: '10px', cursor: 'pointer' }}
                                onClick={handleBadgeClick} // Add this onClick handler
                            >
                                <Badge count={count} style={{
                                    right: '10px',
                                }}>
                                    <ToolOutlined style={{
                                        backgroundColor: colorHeaderText,
                                        border: '1px solid' + colorHeaderText,
                                        borderRadius: '15px',
                                        padding: '10px',
                                        color: 'white',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                    }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                </Badge>
                            </div>
                        </Col>
                    </Row>
                    {/*<Row>*/}
                    {/*    <Space.Compact style={{margin: "10px 20px", width: '100%'}}>*/}
                    {/*        <Input placeholder="Rechercher..."/>*/}
                    {/*        <Button type="primary" style={{*/}
                    {/*            color: colorHeaderText, backgroundColor:*/}
                    {/*            colorHeaderButtonBg, border: '1px solid' + colorHeaderVerticalBar,*/}
                    {/*            height: '40px'*/}
                    {/*        }}><SearchOutlined style={{fontSize: '15px'}}/></Button>*/}
                    {/*    </Space.Compact>*/}
                    {/*</Row>*/}
                    {renderComponent()}
                </div>
            ) : (
                <Layout style={{height: "100%"}}>
                    <Sider trigger={null} collapsible collapsed={collapsed}
                           style={{backgroundColor: "#FFFFFF", height: "100vh"}}>
                        <div className="demo-logo-vertical"/>
                        <div>
                            <img src={logo} style={{height: '60px', display: 'flex', margin: 'auto'}}/>
                        </div>
                        <div style={{marginTop: '20px'}}>
                            <Menu style={{borderInlineEnd: '0px'}}
                                  theme="light"
                                  mode="inline"
                                  defaultSelectedKeys={['1']}
                                  onClick={(key) => changeContent(key.key)}
                                  items={[
                                      {
                                          key: '1',
                                          icon: <HomeOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>,
                                          label: t('Accueil'),
                                      },
                                      {
                                          key: '2',
                                          icon: <TagsOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>,
                                          label: t('Produits'),
                                      },
                                      {
                                          key: '3',
                                          icon: <TagsOutlined id={"createProduct"} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>,
                                          label: t('Créer un produit'),
                                      },
                                      {
                                          key: '4',
                                          icon: <ShopOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>,
                                          label: t('Commandes'),
                                      },
                                      {
                                          key: '5',
                                          icon: <SettingOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>,
                                          label: t('Paramètres du site'),
                                      },
                                      {
                                          key: '7',
                                          icon: <BankOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>,
                                          label: t('Aperçu Bancaire'),
                                      },
                                      {
                                          key: '8',
                                          icon: <SettingOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>,
                                          label: t('Paramètres'),
                                      },
                                      {
                                          key: '9',
                                          icon: <UploadOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>,
                                          label: t('Retour au site'),
                                          onClick: () => {
                                              window.location.href = "/"
                                          },
                                      },
                                      {
                                          key: '10',
                                          icon: <ExportOutlined style={{transform: 'rotate(0.5turn)'}} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>,
                                          label: t('Déconnexion'),
                                          onClick: () => {
                                              window.location.href = "/logout"
                                          },
                                      },
                                  ]}
                            />
                        </div>
                    </Sider>
                    <Layout style={{background: "white"}}>
                        <Header style={{padding: 0, background: colorBgContainer}}>
                            <Button
                                type="text"
                                icon={collapsed ? <MenuUnfoldOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/> : <MenuFoldOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
                                onClick={() => setCollapsed(!collapsed)}
                                style={{
                                    fontSize: '16px',
                                    width: 64,
                                    height: 64,
                                }}
                            />
                            {/*<Space.Compact style={{marginLeft: '50px', width: '350px'}}>*/}
                            {/*    <Input placeholder="Rechercher un produit..." style={{width: '100%'}}/>*/}
                            {/*    <Button type="primary" style={{*/}
                            {/*        color: colorHeaderText, backgroundColor:*/}
                            {/*        colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,*/}
                            {/*        height: '40px'*/}
                            {/*    }}><SearchOutlined/></Button>*/}
                            {/*</Space.Compact>*/}
                            <div style={{
                                justifyContent: 'end',
                                width: '20%',
                                float: 'right',
                                alignItems: 'center',
                                display: 'flex',
                                height: '64px',
                                marginRight: '20px'
                            }}>
                                <a href={'/logout'}>
                                <Button type="primary" style={{
                                    color: colorHeaderText, backgroundColor:
                                    colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                                    height: '40px', marginRight: '10px'
                                }}><LogoutOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Button>
                                </a>
                                {/*<Button type="primary" style={{*/}
                                {/*    color: colorHeaderText, backgroundColor:*/}
                                {/*    colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,*/}
                                {/*    height: '40px', marginRight: '10px'*/}
                                {/*}}><MailOutlined/></Button>*/}
                                {/*<Button type="primary" style={{*/}
                                {/*    color: colorHeaderText, backgroundColor:*/}
                                {/*    colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,*/}
                                {/*    height: '40px', marginRight: '10px'*/}
                                {/*}}><BellOutlined/></Button>*/}
                                <Avatar shape="square" style={{height: '40px', minWidth: '40px'}} size="large"
                                        icon={<UserOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}/>
                            </div>
                        </Header>
                        {content === "1" ? (
                            <Content
                                style={{
                                    margin: '0px 16px',
                                    padding: 24,
                                    minHeight: 280,
                                    background: "#F3F5F6",
                                    borderRadius: '15px'
                                }}
                            >
                                <Home/>
                            </Content>
                        ) : content === "2" ? (
                            <Content
                                style={{
                                    margin: '0px 16px',
                                    padding: 24,
                                    minHeight: 280,
                                    background: "#F3F5F6",
                                    borderRadius: '15px'
                                }}
                            >
                                <ProductsAdmin/>
                            </Content>
                        ) : content === "3" ? (
                            <Content
                                style={{
                                    margin: '0px 16px',
                                    padding: 24,
                                    minHeight: 280,
                                    background: "#F3F5F6",
                                    borderRadius: '15px'
                                }}
                            >
                                <CreateProduct/>
                            </Content>
                        ) : content === "4" ? (
                            <Content
                                style={{
                                    margin: '0px 16px',
                                    padding: 24,
                                    minHeight: 280,
                                    background: "#F3F5F6",
                                    borderRadius: '15px'
                                }}
                            >
                                <CommandsAdmin/>
                            </Content>
                        ) : content === "5" ? (
                            <Content
                                style={{
                                    margin: '0px 16px',
                                    padding: 24,
                                    minHeight: 280,
                                    background: "#F3F5F6",
                                    borderRadius: '15px'
                                }}
                            >
                                <WebsiteSettings/>
                            </Content>
                        ) : content === "6" ? (
                            <Content
                                style={{
                                    margin: '0px 16px',
                                    padding: 24,
                                    minHeight: 280,
                                    background: "#F3F5F6",
                                    borderRadius: '15px'
                                }}
                            >
                                <CompanyView/>
                            </Content>
                        ) : content === "7" ? (
                            <Content
                                style={{
                                    margin: '0px 16px',
                                    padding: 24,
                                    minHeight: 280,
                                    background: "#F3F5F6",
                                    borderRadius: '15px'
                                }}
                            >
                                <BankView/>
                            </Content>
                        ) : content === "8" ? (
                            <Content
                                style={{
                                    margin: '0px 16px',
                                    padding: 24,
                                    minHeight: 280,
                                    background: "#F3F5F6",
                                    borderRadius: '15px'
                                }}
                            >
                                <Settings/>
                            </Content>
                        ) : content === "9" ? (
                            <></>
                        ) : content === "10" ? (
                            <></>
                        ) : null}
                    </Layout>
                </Layout>
            )
            }
        </div>
    );
};


export default Admin;