import "./style.css";
import { Helmet } from 'react-helmet';
import {Badge, Button, Col, Divider, Dropdown, Input, Menu, MenuProps, Row, Space, Drawer} from "antd";
import {
    DownOutlined,
    FacebookOutlined, HeartOutlined,
    InstagramOutlined,
    LinkedinOutlined, PhoneOutlined,
    SearchOutlined, ShoppingCartOutlined, UserOutlined,
    YoutubeOutlined,
    LeftOutlined, RightOutlined,
    MenuOutlined
} from "@ant-design/icons";
import logo from "../../logo.svg";
import logo2 from "../../img/poseidon_logo_finish-04.webp";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Logout from "../Misc/Logout";
import {User} from "../User";
import {GetCartCount,GetCartCountStorage} from "../Cart";
import {createOrder} from "../Order";
import Translate from "./translate";
import {useTranslation} from "react-i18next";
import FavoriteModalMobile from "../Products/FavoriteModalMobile";
import FavoriteModal from "../Products/FavoriteModal";

let user_first_name = "";
if (localStorage.getItem("token")) {
    const user = await User()
    if (user) {
        user_first_name = user.firstname
    }

}


export function HeaderView() {
    const {t} = useTranslation();
    const colorHeaderVerticalBar = "#EAEAEA"
    const colorHeaderText = "#09154C"
    const colorHearderSearch = "rgba(0,0,0,0.0)"
    const colorHeaderBottomText = "#FFFFFF"
    const colorHeaderButtonBg = "#7ED957"

    const ButtonGroup = Button.Group;

    const [count, setCount] = useState(0);

    useEffect(() => {
        const fetchCartCount = async () => {
            try {
                setCount(await GetCartCountStorage());
            } catch (error) {
                // handle error
            }
        };

        fetchCartCount();
    }, [count]);

    const increase = () => {
        setCount(count + 1);
    };

    const decline = () => {
        let newCount = count - 1;
        if (newCount < 0) {
            newCount = 0;
        }
        setCount(newCount);
    };

    const handleLogout = () => {
        Logout();
    };


    const categories = [
        t('Accastillage'),
        t('Accessoires'),
        t('Autres'),
        t('Confort'),
        t('Cordage'),
        t('Électricité'),
        t('Électronique'),
        t('Entretien'),
        t('Loisirs'),
        t('Moteur'),
        t('Mouillage'),
        t('Navigation'),
        t('Sécurité'),
        t('Vêtements')

    ];

    const category: MenuProps['items'] = categories.map((cat, index) => ({
        key: (index + 1).toString(),
        label: (
            <a href={`/shop?name=${cat}`} rel="noopener noreferrer">
            {cat}
                </a>
                ),
            }));

    const SubMenu = Menu.SubMenu;

    const handleMenuClick = (e: { key: React.Key; keyPath: React.Key[]; item: React.ReactInstance }) => {
        console.log('Clicked:', e);
    }

    const translateToEnglish = () => {
        new (window as any).google.translate.TranslateElement({
            pageLanguage: 'fr',
            includedLanguages: 'en',
            layout: (window as any).google.translate.TranslateElement.InlineLayout.SIMPLE
        }, 'google_translate_element');
    };
    useEffect(() => {
        document.getElementById('google_translate_button')?.addEventListener('click', () => {
            translateToEnglish();
        });

        return () => {
            // Nettoyer les écouteurs d'événements si nécessaire
            document.getElementById('google_translate_button')?.removeEventListener('click', () => {
                translateToEnglish();
            });
        };
    }, []);


    const subMenu1 = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1"><a href={`/shop?name=Accastillage`}>{t('Accastillage')}</a></Menu.Item>
            <Menu.Item key="2"><a href={`/shop?name=Accessoires`}>{t('Accessoires')}</a></Menu.Item>
            <Menu.Item key="3"><a href={`/shop?name=Autres`}>{t('Autres')}</a></Menu.Item>
            <Menu.Item key="4"><a href={`/shop?name=Confort`}>{t('Confort')}</a></Menu.Item>
            <Menu.Item key="5"><a href={`/shop?name=Cordage`}>{t('Cordage')}</a></Menu.Item>
            <Menu.Item key="6"><a href={`/shop?name=Électricité`}>{t('Électricité')}</a></Menu.Item>
            <Menu.Item key="7"><a href={`/shop?name=Électronique`}>{t('Électronique')}</a></Menu.Item>
            <Menu.Item key="8"><a href={`/shop?name=Entretien`}>{t('Entretien')}</a></Menu.Item>
            <Menu.Item key="9"><a href={`/shop?name=Loisirs`}>{t('Loisirs')}</a></Menu.Item>
            <Menu.Item key="10"><a href={`/shop?name=Moteur`}>{t('Moteur')}</a></Menu.Item>
            <Menu.Item key="11"><a href={`/shop?name=Mouillage`}>{t('Mouillage')}</a></Menu.Item>
            <Menu.Item key="12"><a href={`/shop?name=Navigation`}>{t('Navigation')}</a></Menu.Item>
            <Menu.Item key="13"><a href={`/shop?name=Sécurité`}>{t('Sécurité')}</a></Menu.Item>
            <Menu.Item key="14"><a href={`/shop?name=Vêtements`}>{t('Vêtements')}</a></Menu.Item>
        </Menu>
    );

    const subMenu2 = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="3"><a>{t('Moteur')}</a></Menu.Item>
            <Menu.Item key="4"><a>{t('Confort')}</a></Menu.Item>
        </Menu>
    );

    const lang: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer">
                    {t('Français')}
                </a>
            ),
        },
        // {
        //     key: '2',
        //     label: (
        //         <a target="_blank" rel="noopener noreferrer">
        //             English
        //         </a>
        //     ),
        // },
        // {
        //     key: '3',
        //     label: (
        //         <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
        //             Español
        //         </a>
        //     ),
        // },
    ];

    const currency: MenuProps['items'] = [
        // {
        //     key: '1',
        //     label: (
        //         <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
        //             USD
        //         </a>
        //     ),
        // },
        // {
        //     key: '2',
        //     label: (
        //         <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
        //             GBP
        //         </a>
        //     ),
        // },
        // {
        //     key: '3',
        //     label: (
        //         <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
        //             CHF
        //         </a>
        //     ),
        // },
    ];

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const [childrenShopDrawer, setChildrenShopDrawer] = useState(false);

    const onChildrenShopDrawer = () => {
        setChildrenShopDrawer(true);
    };

    const onChildrenShopDrawerClose = () => {
        setChildrenShopDrawer(false);
    };

    // Step 1: Define a state variable to store the input value
    const [inputValue, setInputValue] = useState<string>('');
    const [placeholder, setPlaceholder] = useState<string>('');

    // Step 3: Implement a function to handle input value changes
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    // Step 4: When the user sends a console log request, access the value
    const handleConsoleLog = () => {
        console.log('Input Value:', inputValue);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            // Trigger the click event on the <a> tag when Enter is pressed
            const link = document.getElementById('shopLink');
            if (link) {
                link.click();
            }
        }
    };

    useEffect(() => {
        // Extract the 'name' query parameter from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const nameParam = urlParams.get('name');

        if (nameParam) {
            // Set the input value and placeholder from the 'name' parameter
            setInputValue(nameParam);
            setPlaceholder(nameParam);
        }
    }, []);


    return (
        <div className={'header'}
             style={{backgroundColor: colorHeaderBottomText, height: '100%', lineHeight: '30px', paddingInline: '0'}}>

            <Helmet>
                <meta property="og:image" content="https://www.poseidonshop.fr/uploads/Images/Icons/poseidon_logo.webp"/>
            </Helmet>
            <style>
                {`
                .goog-te-menu-frame:not(.ready) .goog-te-menu2-item {
           	     display: none;
         		 }

                /* Montrez uniquement l'anglais et le français */
                .goog-te-menu-frame .goog-te-menu2-item:nth-child(6) /* English */,
                .goog-te-menu-frame .goog-te-menu2-item:nth-child(23) /* French */ {
                display: block;
            	}
            	`}
            </style>
            {/*<GoogleTranslateWidget/>*/}
            <div>
                <style>
                    {`
                                                  
                                @media (max-width: 320px) {
                                    
                                    .header-container .anticon {
                                        font-size: 14px; /* Adjust the font size as needed */
                                    }
                                }
                                
                                @media (max-width: 768px) {
                                                                       
                                    .header-container img {
                                        height: 70px; /* Adjust the height as needed */
                                    }
                                    
                                    .header-container .anticon {
                                        font-size: 17px; /* Adjust the font size as needed */
                                    }
                                    
                                }
                                
                                
                                .anticon svg {
                                      display: block !important;
                                }
                                
                                :where(.css-dev-only-do-not-override-txh9fw).ant-btn >span {
                                        display: block !important;
                                }
                               
                                .gg-anchor {
                                    box-sizing: border-box;
                                    position: relative;
                                    display: block;
                                    transform: scale(var(--ggs,1));
                                    width: 2px;
                                    height: 14px;
                                    background: currentColor;
                                    border-radius: 8px
                                }
                                .gg-anchor::after,
                                .gg-anchor::before {
                                    content: "";
                                    display: block;
                                    box-sizing: border-box;
                                    position: absolute;
                                    height: 6px;
                                    border: 2px solid
                                }
                                .gg-anchor::after {
                                    width: 12px;
                                    border-bottom-left-radius: 20px;
                                    border-bottom-right-radius: 20px;
                                    border-top-color: transparent;
                                    left: -5px;
                                    bottom: 2px
                                }
                                .gg-anchor::before {
                                    width: 6px;
                                    border-radius: 8px;
                                    top: -4px;
                                    left: -2px
                                }
                            `}
                </style>
                {windowWidth < 1100 ? (
                    <div style={{backgroundColor: colorHeaderText}} className="header-container">
                        <Row>
                            <Col style={{margin:'10px auto'}}>
                                <Link to="/">
                                    <img alt="Poseidonshop.fr" src={logo2} style={{maxWidth: '100%', width:'100%', height : '80px'}}/>
                                </Link>
                            </Col>
                            <FavoriteModalMobile/>

                        </Row>

                        <Col flex={5} style={{margin: "0px 20px"}}>
                            <Space.Compact
                                style={{width: '100%', position: 'relative', height: '50px'}}>
                                <Dropdown menu={{items: category}} trigger={['click']}>
                                    <a style={{justifyContent: 'center', alignItems: 'center', display: 'inline-flex', position: 'relative',
                                        color: colorHeaderText,
                                        backgroundColor : '#fff',
                                        borderLeft: '1px solid' + colorHeaderVerticalBar,
                                        borderTop: '1px solid' + colorHeaderVerticalBar,
                                        borderBottom: '1px solid' + colorHeaderVerticalBar,
                                        borderRadius: '5% 0% 0% 5%',
                                        boxShadow: '0 2px 0 rgba(5, 145, 255, 0.1)'
                                    }}>
                                        <div style={{margin: '6px 20px 0px 20px', gap: '0px'}}>
                                            {/*{t('Catégories')}*/}
                                            {/*<DownOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>*/}
                                            <MenuOutlined style={{fontSize: '25px'}} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /> {/* Use MenuOutlined icon */}
                                        </div>
                                    </a>
                                </Dropdown>
                                <a id="shopLink" href={`/shop?name=${inputValue}`} style={{display: 'none'}}>
                                    {t('Redirect')}
                                </a>
                                <Input placeholder={placeholder || "Rechercher un produit..."}
                                       value={inputValue} // Link the input value to state
                                       onChange={handleInputChange} // Handle input changes
                                       onKeyPress={handleKeyPress} style={{fontSize: '17px',borderRadius : '0px',boxShadow : '0 2px 0 rgba(5, 145, 255, 0.1)'}}
                                />
                                <a href={'/shop?name=' + inputValue}>
                                    <Button type="primary"
                                            onClick={handleConsoleLog}
                                            style={{
                                                color: colorHeaderText,
                                                backgroundColor : 'rgb(126, 217, 87)',
                                                border: '1px solid' + 'rgb(126, 217, 87)',
                                                height: '50px',
                                                fontSize : '18px'
                                            }}><SearchOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Button>
                                </a>
                            </Space.Compact>
                        </Col>
                        <br/>
                    </div>

                ) : (
                    <div>
                        <Row style={{color: colorHeaderText, margin: '10px 0'}}>
                            <Col flex={1} style={{justifyContent: 'end', display: 'inline-flex'}}>
                                <p style={{marginRight: '10px',fontWeight:'bold'}}>{t('Marketplace de pièces de réemploi pour bateaux')}</p>
                            </Col>
                            <Col flex={1} style={{borderLeft: '2px solid' + colorHeaderVerticalBar,display: 'inline-flex'}}>
                                <p style={{marginLeft: '10px',fontWeight:'bold'}}>{t('Langue')}</p>
                                <Translate/>
                                <div style={{
                                    borderLeft: '2px solid' + colorHeaderVerticalBar,
                                    height: '30px',
                                    marginLeft: '10px',
                                    position: 'relative'
                                }}></div>
                                <p style={{marginLeft: '10px',fontWeight:'bold'}}>{t('Devise')}</p>
                                {/*<Dropdown menu={{items: currency}} trigger={['click']}>*/}
                                    <Space style={{marginLeft: '10px'}}>EUR<DownOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Space>
                                {/*</Dropdown>*/}
                                <div style={{
                                    borderLeft: '2px solid' + colorHeaderVerticalBar,
                                    height: '30px',
                                    marginLeft: '10px',
                                    position: 'relative'
                                }}></div>
                                {/*<a style={{*/}
                                {/*    position: 'relative', display: 'inline-flex', justifyContent:*/}
                                {/*        'center', alignItems: 'center', marginLeft: '10px', color: colorHeaderText*/}
                                {/*}} href={"/team"}>En savoir +</a>*/}
                                {/*<div style={{*/}
                                {/*    borderLeft: '2px solid' + colorHeaderVerticalBar,*/}
                                {/*    height: '30px',*/}
                                {/*    marginLeft: '10px',*/}
                                {/*    position: 'relative'*/}
                                {/*}}></div>*/}
                                {/*TODO : Anchor pour les partenaires*/}
                                {/*<a style={{*/}
                                {/*    position: 'relative', display: 'inline-flex', justifyContent:*/}
                                {/*        'center', alignItems: 'center', marginLeft: '10px', color: colorHeaderText*/}
                                {/*}} href={"/partenaires"}>Partenaires</a>*/}
                                {/*<div style={{*/}
                                {/*    borderLeft: '2px solid' + colorHeaderVerticalBar,*/}
                                {/*    height: '30px',*/}
                                {/*    marginLeft: '10px',*/}
                                {/*    position: 'relative'*/}
                                {/*}}></div>*/}
                                <p style={{marginLeft: '10px',fontWeight:'bold'}}>{t('Suivez - nous !')}</p>
                                <Row>
                                    <Col flex={1} style={{
                                        position: 'relative', display: 'inline-flex', justifyContent:
                                            'center', alignItems: 'center', marginLeft: '20px'
                                    }}>
                                        <a style={{color: colorHeaderText}} target={"_blank"}
                                           href={'https://www.facebook.com/profile.php?id=100088339946641&__cft__[0]=AZVsDXlivPr6FnyU84kY6T42ymANSgAY85RoCW64f1LaqCmRBWvnsQB7wU1lIfsbq_W2XBMrcm1yHTryJQGQ4XwXka5pCmmT0QNO0oKFJAvuBcR5CmiBcRYnmZf4HLAFg0Q&__tn__=-]K-R'}>
                                            <FacebookOutlined style={{fontSize: '15px'}} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                        </a>
                                    </Col>
                                    <Col flex={1} style={{
                                        position: 'relative', display: 'inline-flex', justifyContent:
                                            'center', alignItems: 'center', marginLeft: '10px'
                                    }}>
                                        <a href={'https://www.instagram.com/captainchercheur/'} target={"_blank"}
                                           style={{color: colorHeaderText}}>
                                            <InstagramOutlined style={{fontSize: '15px'}} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                        </a>
                                    </Col>
                                    <Col flex={1} style={{
                                        position: 'relative', display: 'inline-flex', justifyContent:
                                            'center', alignItems: 'center', marginLeft: '10px'
                                    }}>
                                        <a href={'https://www.youtube.com/channel/UC8SfQjh6up0Fy0b9HP9_tWg'} target={"_blank"}
                                           style={{color: colorHeaderText}}>
                                            <YoutubeOutlined style={{fontSize: '15px'}} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                        </a>
                                    </Col>
                                    <Col flex={1} style={{
                                        position: 'relative', display: 'inline-flex', justifyContent:
                                            'center', alignItems: 'center', marginLeft: '10px'
                                    }}>
                                        <a target={"_blank"} href={'https://fr.linkedin.com/company/captainchercheur'}
                                           style={{color: colorHeaderText}}>
                                            <LinkedinOutlined style={{fontSize: '15px'}} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                        </a>
                                        {/*<a onClick={handleLogout}><LogoutOutlined style={{fontSize: '15px'}}/></a>*/}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider style={{
                            borderTop: '1px solid ' + colorHeaderVerticalBar,
                            margin: '0',
                            paddingInline: '30px'
                        }}/>
                         <div style={{width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: colorHeaderButtonBg,}}>
                            <a style={{backgroundColor: colorHeaderButtonBg, textAlign: 'center', color: colorHeaderBottomText, padding: '10px 20px', textDecoration: 'none' , fontSize:'20px'}} href={"/shop"}>
                                Livraison <span style={{color:"#0477F8",fontWeight:'bold'}}>GRATUITE</span> toute l'année 2024 ! Valable dès <span style={{fontWeight:'bold'}}>200€</span> d'achats en France métropolitaine
                            </a>
                         </div>
                        {/*<div style={{width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: colorHeaderButtonBg}}>*/}
                        {/*    <a style={{backgroundColor: colorHeaderButtonBg, textAlign: 'center', color: colorHeaderBottomText, padding: '10px 20px', textDecoration: 'none' , fontSize:'20px'}} href={"/shop"}>*/}
                        {/*        Commandez dès maintenant avec le code promo <span style={{color:"#0477F8"}}>JANV5</span> et obtenez 5% de réduction (valable jusqu'au 31/01)*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                        <Divider style={{
                            borderTop: '1px solid ' + colorHeaderVerticalBar,
                            margin: '0',
                            paddingInline: '30px'
                        }}/>

                        <Row style={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '10px'
                        }}>
                            <Col flex={1} style={{
                                justifyContent: 'end',
                                alignItems: 'center',
                                position: 'relative',
                                display: 'inline-flex'
                            }}>
                                <Link to="/">
                                    <img alt={"logo"} src={logo} style={{width: '100px'}}/>
                                </Link>
                            </Col>
                            <Col flex={5}>
                                <Space.Compact
                                    style={{width: '75%', left: '10%', position: 'relative', height: '50px'}}>
                                    <Dropdown menu={{items: category}} trigger={['click']}>
                                        <a style={{justifyContent: 'center', alignItems: 'center', display: 'inline-flex', position: 'relative',
                                            color: colorHeaderText,
                                            borderLeft: '1px solid' + colorHeaderVerticalBar,
                                            borderTop: '1px solid' + colorHeaderVerticalBar,
                                            borderBottom: '1px solid' + colorHeaderVerticalBar,
                                            borderRadius: '5% 0% 0% 5%',
                                            boxShadow: '0 2px 0 rgba(5, 145, 255, 0.1)'
                                        }}>
                                            <Space style={{marginRight: '10px', marginLeft: '10px'}}>
                                                {t('Catégories')}
                                                <DownOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                            </Space>
                                        </a>
                                    </Dropdown>
                                    <a id="shopLink" href={`/shop?name=${inputValue}`} style={{display: 'none'}}>
                                        {t('Redirect')}
                                    </a>
                                    <Input placeholder={placeholder || "Rechercher un produit..."}
                                           value={inputValue} // Link the input value to state
                                           onChange={handleInputChange} // Handle input changes
                                           onKeyPress={handleKeyPress} style={{fontSize: '17px',borderRadius : '0px',boxShadow : '0 2px 0 rgba(5, 145, 255, 0.1)'}}
                                    />
                                    <a href={'/shop?name=' + inputValue}>
                                        <Button type="primary"
                                                onClick={handleConsoleLog}
                                                style={{
                                                    color: colorHeaderText,
                                                    backgroundColor: 'rgb(126, 217, 87)',
                                                    border: '1px solid' + 'rgb(126, 217, 87)',
                                                    height: '50px',
                                                    fontSize : '18px'
                                                }}><SearchOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Button>
                                    </a>
                                </Space.Compact>
                            </Col>
                            <Col flex={2}
                                 style={{
                                     display: 'inline-flex',
                                     gap: '3%',
                                     alignItems: 'center',
                                     justifyContent: 'center'
                                 }}>
                                <FavoriteModal/>
                                <a href={'tel:+33468229025'} style={{display: 'inline-flex'}}>
                                    <PhoneOutlined style={{
                                        fontSize: '26px',
                                        color: colorHeaderText,
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        marginRight: '10px',
                                        transform: 'scaleX(-1)'
                                    }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                    <p style={{color: colorHeaderText, fontSize: '18px'}}>04 68 22 90 25</p>
                                </a>

                                {/*<a style={{display: 'inline-flex'}} href={"/dashboard"}>*/}
                                {/*    <HeartOutlined style={{*/}
                                {/*        fontSize: '22px',*/}
                                {/*        color: colorHeaderText,*/}
                                {/*        display: 'inline-flex',*/}
                                {/*        alignItems: 'center',*/}
                                {/*        marginRight: '10px'*/}
                                {/*    }}/>*/}
                                {/*    <p style={{color: colorHeaderText}}>Liste de souhaits</p>*/}
                                {/*</a>*/}
                                {localStorage.getItem('token') ? (

                                    <a style={{display: 'inline-flex'}} href={"/dashboard"}>
                                        <UserOutlined style={{
                                            fontSize: '26px',
                                            color: colorHeaderText,
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            marginRight: '10px'
                                        }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                        <p style={{
                                            color: colorHeaderText,
                                            fontSize: '18px'
                                        }}>{t('Bienvenue')}, {user_first_name}</p>
                                    </a>
                                ) : (
                                    <a style={{display: 'inline-flex'}} href={"/login"}>
                                        <UserOutlined style={{
                                            fontSize: '26px',
                                            color: colorHeaderText,
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            marginRight: '10px'
                                        }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                        <p style={{color: colorHeaderText, fontSize: '18px'}}>{t('Mon Compte')}</p>
                                    </a>
                                )}

                                <a style={{display: 'inline-flex'}} href={"/cart"}>
                                    <Badge count={count} style={{right: '10px'}}>
                                        <ShoppingCartOutlined style={{
                                            fontSize: '26px',
                                            color: colorHeaderText,
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            marginRight: '10px'
                                        }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                    </Badge>
                                    {/*<ButtonGroup>*/}
                                    {/*    <Button onClick={decline} icon={<MinusOutlined />} />*/}
                                    {/*    <Button onClick={increase} icon={<PlusOutlined />} />*/}
                                    {/*</ButtonGroup>*/}
                                    <p style={{color: colorHeaderText, fontSize: '18px'}}>{t('Panier')}</p>
                                </a>
                            </Col>
                        </Row>
                        <Row
                            style={{backgroundColor: colorHeaderText, marginTop: '10px', color: colorHeaderBottomText}}>
                            {windowWidth > 1100 && windowWidth < 1400 ? (
                                <Col flex={1} style={{display: 'inline-flex', justifyContent: 'center'}}>
                                    <Dropdown overlay={
                                        <Menu onClick={handleMenuClick}>
                                            {subMenu1}

                                            {/*<SubMenu key="sub2" title="Plein Air">*/}
                                            {/*    {subMenu2}*/}
                                            {/*</SubMenu>*/}
                                        </Menu>
                                } trigger={['click']}>
                                    <a className="ant-dropdown-link" onClick={(e) =>
                                        e.preventDefault()} style={{
                                        color: colorHeaderBottomText, justifyContent: 'center',
                                        display: 'inline-flex', alignItems: 'center', position: 'relative'
                                    }} href={"/"}>
                                        Toutes les Catégories <DownOutlined style={{marginLeft: '10px'}}
                                                                        onPointerEnterCapture={undefined}
                                                                        onPointerLeaveCapture={undefined}/>
                                    </a>
                                </Dropdown>
                            </Col>
                            ):(
                                <>
                                </>
                            )}

                            <Col flex={3} style={{display: 'inline-flex', height: '60px'}}>

                                {windowWidth > 1100 && windowWidth < 1400? (
                                    <>
                                        <Col flex={1} style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <a style={{position: 'relative', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', color: colorHeaderBottomText}} id="/shop" href={"/shop"}>Boutique</a>
                                        </Col>
                                    </>

                                ) : windowWidth >= 1400 && windowWidth < 1700 ? (
                                    <>
                                        {categories.slice(0, 8).map((category, index) => (
                                            <Col key={index} flex={1} style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <a style={{
                                                    position: 'relative',
                                                    display: 'inline-flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: colorHeaderBottomText,
                                                }} href={`/shop?name=${category}`}>
                                                    {category}
                                                </a>
                                            </Col>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {categories.map((category, index) => (
                                            <Col key={index} flex={1} style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <a style={{
                                                    position: 'relative',
                                                    display: 'inline-flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: colorHeaderBottomText,
                                                }} href={`/shop?name=${category}`}>
                                                    {category}
                                                </a>
                                            </Col>
                                        ))}
                                    </>
                                )}
                            </Col>


                            <Col flex={3} style={{display: 'inline-flex', height: '60px'}}>


                                {windowWidth > 1100 && windowWidth < 1400? (

                                            <Col flex={1} style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <a style={{position: 'relative', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', color: colorHeaderBottomText}} id="/contact" href={"/contact"}>{t('Contact')}</a>
                                            </Col>


                                ) : (
                                        <Col flex={1} style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <a style={{position: 'relative', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', color: colorHeaderBottomText}} id="/contact" href={"/contact"}>{t('Contact')}</a>
                                        </Col>

                                )}
                            </Col>

                            <Col style={{
                                display: 'inline-flex',
                                backgroundColor: colorHeaderButtonBg,
                                minWidth: '100px'
                            }}>
                                <a style={{
                                    textAlign: 'center',
                                    display: 'inline-flex',
                                    margin: 'auto 20px',
                                    color: colorHeaderBottomText
                                }} id="add_product" href={"/dashboard"}>{t('Déposer une annonce')}</a>
                            </Col>
                            {/*<Col flex={3} style={{display: 'inline-flex'}}>*/}
                            {/*    <Col flex={1}*/}
                            {/*         style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>*/}
                            {/*        <a style={{*/}
                            {/*            position: 'relative', display: 'inline-flex', justifyContent:*/}
                            {/*                'center', alignItems: 'center', color: colorHeaderBottomText*/}
                            {/*        }} id="promos" href={"/shop"}>Promos</a>*/}
                            {/*    </Col>*/}
                            {/*    <Col flex={1}*/}
                            {/*         style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>*/}
                            {/*        <a style={{*/}
                            {/*            position: 'relative', display: 'inline-flex', justifyContent:*/}
                            {/*                'center', alignItems: 'center', color: colorHeaderBottomText*/}
                            {/*        }} id="best_sellers" href={"/shop"}>Meilleures Ventes</a>*/}
                            {/*    </Col>*/}
                            {/*    <Col flex={1}*/}
                            {/*         style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>*/}
                            {/*        <a style={{*/}
                            {/*            position: 'relative', display: 'inline-flex', justifyContent:*/}
                            {/*                'center', alignItems: 'center', color: colorHeaderBottomText*/}
                            {/*        }} id="new" href={"/shop"}>Nouveautés</a>*/}
                            {/*    </Col>*/}
                            {/*</Col>*/}
                        </Row>
                    </div>
                )}
            </div>
        </div>
    );
}

