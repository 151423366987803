import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faStore, faPlusSquare, faShoppingCart, faUser } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import ClientMobile from "../../pages/Dashboard/Client/indexMobile";
import Client from "../../pages/Dashboard/Client";
import DashboardMobile from "../../pages/Dashboard/Vendor/indexMobile";
import Dashboard from "../../pages/Dashboard/Vendor";
import AdminMobile from "../../pages/Dashboard/Admin/indexMobile";
import Admin from "../../pages/Dashboard/Admin";
import BecomeVendor from "../Dashboard/Client/BecomeVendor";

export default function NavbarMobile() {
    return (
        <nav className="navigation">
            <ul className="navigation-menu">
                <li>
                    <a href="/">
                        <FontAwesomeIcon icon={faHome} />
                        <span>Accueil</span>
                    </a>
                </li>
                <li>
                    <a href="/shop">
                        <FontAwesomeIcon icon={faStore} />
                        <span>Boutique</span>
                    </a>
                </li>
                <li>
                    {localStorage.getItem('role') === 'vendor' || localStorage.getItem('role') === 'admin' ? (

                        <a href="/dashboard" onClick={() => localStorage.setItem('createProduct', 'true')}>
                                <FontAwesomeIcon icon={faPlusSquare} />
                                <span>Publier</span>
                            </a>

                    ):(
                        <a href="/">
                            <FontAwesomeIcon icon={faPlusSquare} />
                            <span>Publier</span>
                        </a>

                    )
                    }
                </li>
                <li>
                    <a href="/cart">
                        <FontAwesomeIcon icon={faShoppingCart} />
                        <span>Panier</span>
                    </a>
                </li>
                <li>
                    <a href="/dashboard">
                        <FontAwesomeIcon icon={faUser} />
                        <span>Compte</span>
                    </a>
                </li>
            </ul>
        </nav>
    );
}
