import {
    Button,
    Col,
    Input,
    Row,
    Select,
    Space,
    Table,
    Pagination,
    InputNumber,
    Menu,
    MenuProps,
    Avatar,
    Layout, Typography, Image
} from "antd";
import React, {useEffect, useState} from "react";
import {
    EditOutlined,
    SearchOutlined,
    DeleteOutlined,
    UserOutlined,
    RightOutlined,
    ShopOutlined, SettingOutlined, PoweroffOutlined, ArrowLeftOutlined
} from "@ant-design/icons";
import {getUserById, User, UserInterface} from "../../User";
import {getProductByID, getVendorProduct, GlobalProduct, ProductInterface} from "../../Products/Products";
import {deleteOrder, getMyOrders, getUserOrders, Order} from "../../Order";
import {getExpeditionPriceById} from "../../Expedition";
import {getInvoiceByOrderId} from "../../Media";
import fetchAPI from "../../Fetch";
import {pay, repay} from "../../../pages/Cart/pay";
import { Tooltip } from 'antd';
import {getTransactionDetails} from "../../MangoPay";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {t} from "i18next";
const { Content } = Layout;
const { Title, Text } = Typography;


interface dataSourceInterface {
    key: string,
    id: string,
    date: string,
    state: string,
    priceTotal: number,
    productName: string,
    productImage: number,
    livraison_id: string;
    details : string | null;
}

export default function CommandesClient() {

    const colorHeaderVerticalBar = "#EAEAEA"
    const colorHeaderText = "#09154C"
    const colorHearderSearch = "rgba(0,0,0,0.0)"
    const colorProductBtn = "#7ED957"

    const [ordersInfos, setOrdersInfos] = useState({} as Order[]);
    const [dataFetched, setDataFetched] = useState(false);
    const [options, setOptions] = useState<dataSourceInterface[]>([]);

    useEffect(() => {
        const fetchOrdersInfos = async () => {
            const orders = await getMyOrders();
            console.log(orders)
            setOrdersInfos(orders)
            setDataFetched(true);
            let newOption: dataSourceInterface;
            try {
                let mappedOptions: dataSourceInterface[] = [];
                for (const order of orders) {

                    let productImage = 0
                    let productName = ""
                    let expeditionPrice = 0
                    let priceTotal = 0;
                    for (const subOrder of order.sub_orders) {
                        const product = await getProductByID(subOrder.product_id)
                        console.log(product)
                        productImage = product.product_image
                        productName = product.product_name
                        priceTotal += subOrder.product_price_TTC
                        expeditionPrice = await getExpeditionPriceById(subOrder.expedition_id) * 1.2
                    }
                    if (order.sub_orders[0].state_id !== 7) {

                        newOption = {
                            key: order.id as unknown as string,
                            id: order.id as unknown as string,
                            date: new Date(order.creation_date * 1000).toDateString(),
                            state: order.sub_orders[0].state_id as unknown as string,
                            productName: productName,
                            priceTotal: (expeditionPrice + priceTotal).toFixed(2) as unknown as number,
                            livraison_id: order.sub_orders[0].livraisonId as unknown as string,
                            details :  await getTransactionDetails(order.id).then(transaction => {return transaction.ResultMessage}),
                            productImage: productImage
                        };
                        mappedOptions.push(newOption);

                        // Update the state immediately with the new option
                        setOptions(() => [...mappedOptions]);

                    }


                }
            } catch (error) {
                console.log(error, "No user data found");
            }
        };
        if (!dataFetched) {
            fetchOrdersInfos();
        }
    }, [dataFetched]);

    const onChange = (value: string) => {
        console.log(`selected ${value}`);
    };

    const onSearch = (value: string) => {
        console.log('search:', value);
    };

    const dataSource = options

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Produit(s) commandé(s)',
            dataIndex: 'productName',
            key: 'productName',
            width: '15%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text + "..."}</span>
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '10%',
            sorter: (a: dataSourceInterface, b: dataSourceInterface) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateA.getTime() - dateB.getTime();
            },
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },

        {
            title: 'Status',
            dataIndex: 'state',
            key: 'state',
            width: '15%',
            render: (text: any, record: any) => {
                if (record.state === 1) {
                    return <span style={{
                        color: '#00A3FF',
                        backgroundColor: '#F1FAFF',
                        padding: '5px',
                        border: '1px solid #DCF2E6',
                        borderRadius: '5px'
                    }}>En attente de paiement</span>;
                } else if (record.state === 2) {
                    return <span style={{
                        color: '#17c200',
                        backgroundColor: '#daffdb',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>Paiement réussi</span>;
                } else if (record.state === 3) {
                    return <span style={{
                        color: '#17c200',
                        backgroundColor: '#daffdb',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>En cours de préparation chez le vendeur</span>;
                } else if (record.state === 5) {
                    return <span style={{
                        color: '#f1b041',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{t('Remboursée')}</span>;
                } else if (record.state === 8) {
                    return <span style={{
                        color: '#F1416C',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>
                        <Tooltip title={record.details}>
                          <span>Paiement refusé</span>
                        </Tooltip>
                    </span>;
                } else if (record.state === 9) {
                    return <span style={{
                        color: '#17c200',
                        backgroundColor: '#daffdb',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>
                        <Tooltip title={record.details}>
                          <span>En cours de livraison</span>
                        </Tooltip>
                    </span>;
                } else {
                    return <span style={{
                        color: '#F1416C',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{text}</span>;
                }
            },
        },

        {
            title: 'Total',
            dataIndex: 'priceTotal',
            key: 'priceTotal',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Action',
            key: 'action',
            width: '10%',
            render: (text: any, record: any) => (
                <div style={{display: 'flex'}}>
                    {localStorage.getItem("user_id") === "2378" ?
                        <Button style={{margin: '5px', backgroundColor: "red", color: colorHeaderText}}
                                onClick={async () => {
                                    window.open(await fetchAPI("mangopay/users/pay-in-unique", "POST", true, true).then((response) => {
                                        return response.response.redirectURL
                                    }))
                                }}>
                            Payer la différence
                        </Button>
                        : <></>
                    }
                    <Button style={{margin: '5px', backgroundColor: colorProductBtn, color: colorHeaderText}}
                            onClick={() => {
                                window.location.pathname = "/viewOrderInfos/" + record.id
                            }}>
                        Détails
                    </Button>
                    {record.state === 8 ?
                        <Button style={{margin: '5px', backgroundColor: colorProductBtn, color: colorHeaderText}}
                                onClick={async () => {
                                    toast.success('Vous allez être redirigé vers la page de paiement, veuillez patienter');
                                    console.log(await repay(record.id))
                                }}>
                            Repasser le paiement
                        </Button>
                        : <></>}
                    <Button style={{margin: '5px', backgroundColor: colorProductBtn, color: colorHeaderText}}
                            onClick={async () => {
                                const media = await getInvoiceByOrderId(record.id)
                                window.open("https://beta.captainchercheur.com/media/public/invoice/" + media[0].id)
                            }}>
                        Voir la facture
                    </Button>

                    {record.livraison_id !== null ?
                        <Button onClick={() => {
                            window.open("https://www.chronopost.fr/tracking-no-cms/suivi-page?listeNumerosLT=" + record.livraison_id + "&langue=fr_FR")
                        }}>Suivre mon colis</Button>
                        : <></>
                    }
                </div>
            )
        },
    ];


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const totalItems = dataSource.length;
    const rangeStart = (currentPage - 1) * itemsPerPage + 1;

    const handlePageChange = (page: any) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (current: any, size: any) => {
        setItemsPerPage(size);
    };

    const customPaginationStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };


    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    const items: MenuProps['items'] = [
        {
            label: 'Achats',
            key: 'achats',
        },
        {
            label: 'Ventes',
            key: 'ventes',
        }
    ];

    const [content, setCurrent] = useState('achats');

    const onClick: MenuProps['onClick'] = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };


    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const formatDate = (dateString : any) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
        const year = String(date.getFullYear()).slice(-2);
        return `${day}/${month}/${year}`;
    };


    const getMonthYear = (dateString : any) => {
        const date = new Date(dateString);
        const monthNames = [
            "janvier", "février", "mars", "avril", "mai", "juin",
            "juillet", "août", "septembre", "octobre", "novembre", "décembre"
        ];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${month} ${year}`;
    };



    const renderOrders = () => {
        return options.map((order, index) => (
            <div onClick={() => window.location.pathname = "/viewOrderInfos/" + order.id} key={order.id} style={{ padding: '22px', background: '#fff', borderRadius: '8px', marginBottom: '10px', boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <p style={{ marginBottom: '10px', fontWeight: 'lighter' ,color: 'grey'}}>Achat le {formatDate(order.date)}</p>
                <p>{getStatusText(order.state, order.details)}</p>
                <Row align="middle" style={{flexFlow : 'unset',marginTop : '20px'}}>
                    <Col>
                        <Image src={"https://beta.captainchercheur.com/media/public/product/" + order.productImage} alt={order.productName} width={80} height={80}
                            style={{aspectRatio: '1/1', objectFit: 'cover'}} />
                    </Col>
                    <Col flex="auto" style={{ paddingLeft: '20px' }}>
                        <Title level={4} style={{ marginBottom: '0', fontWeight: 'bold' }}>{order.productName}</Title>
                        <Title level={4} style={{ color :colorProductBtn, fontWeight: 'bold' }}>{order.priceTotal} €</Title>
                    </Col>
                    <Col>
                        <RightOutlined style={{fontSize: '23px', color: '#ccd2dd'}} onPointerEnterCapture={undefined}
                                       onPointerLeaveCapture={undefined} />
                    </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                    <Button style={{ backgroundColor: colorProductBtn, color: colorHeaderText }} onClick={() => window.location.pathname = "/viewOrderInfos/" + order.id}>
                        Détails
                    </Button>
                    {order.state === "8" && (
                        <Button style={{ backgroundColor: colorProductBtn, color: colorHeaderText }} onClick={async () => {
                            toast.success('Vous allez être redirigé vers la page de paiement, veuillez patienter');
                            await repay(parseFloat(order.id));
                        }}>
                            Repasser le paiement
                        </Button>
                    )}
                    <Button style={{ backgroundColor: colorProductBtn, color: colorHeaderText }} onClick={async () => {
                        const media = await getInvoiceByOrderId(parseFloat(order.id));
                        window.open("https://beta.captainchercheur.com/media/public/invoice/" + media[0].id);
                    }}>
                        Voir la facture
                    </Button>
                    {order.livraison_id && (
                        <Button onClick={() => {
                            window.open("https://www.chronopost.fr/tracking-no-cms/suivi-page?listeNumerosLT=" + order.livraison_id + "&langue=fr_FR");
                        }}>Suivre mon colis</Button>
                    )}
                </div>
            </div>
        ));
    };

    const getStatusText = (state: any, details: any) => {
        switch (state) {
            case 1:
                return <span style={{ color: '#00A3FF', backgroundColor: '#F1FAFF', padding: '5px', border: '1px solid #DCF2E6', borderRadius: '14px',fontWeight : 'bold' }}>En attente de paiement</span>;
            case 2:
                return <span style={{ color: '#17c200', backgroundColor: '#daffdb', padding: '5px', border: '1px solid #FFF5F8', borderRadius: '14px',fontWeight : 'bold' }}>Paiement réussi</span>;
            case 3:
                return <span style={{ color: '#17c200', backgroundColor: '#daffdb', padding: '5px', border: '1px solid #FFF5F8', borderRadius: '14px' ,fontWeight : 'bold'}}>En cours de préparation chez le vendeur</span>;
            case 5:
                return <span style={{ color: '#f1b041', backgroundColor: '#FFF5F8', padding: '5px', border: '1px solid #FFF5F8', borderRadius: '14px',fontWeight : 'bold' }}>Remboursée</span>;
            case 8:
                return <Tooltip title={details}>
                    <span style={{ color: '#F1416C', backgroundColor: '#FFF5F8', padding: '5px', border: '1px solid #FFF5F8', borderRadius: '14px' ,fontWeight : 'bold'}}>Paiement refusé</span>
                </Tooltip>;
            default:
                return <span style={{ color: '#F1416C', backgroundColor: '#FFF5F8', padding: '5px', border: '1px solid #FFF5F8', borderRadius: '14px' }}>{state}</span>;
        }
    };

    return (
        <div style={{color: colorHeaderText}}>
            <ToastContainer/>
            {windowWidth < 1100 ? (

                <Layout style={{ backgroundColor: '#fff' }}>
                        <style>
                            {`
                              :where(.css-dev-only-do-not-override-txh9fw).ant-avatar.ant-avatar-icon {
                                font-size: 18px;
                              }    
                        
                              .anticon svg { display: block !important;}
                              
                              .custom-menu {
                                  display: flex;
                                  width: 100%;
                               }
                                
                                .custom-menu .ant-menu-item {
                                  flex: 1; /* Chaque élément prend une part égale de la largeur */
                                  text-align: center; /* Optionnel : pour centrer le texte */
                                }
                        `}
                        </style>

                        <Content style={{ padding: '20px' }}>
                            <div style={{display:'flex',marginBottom: '15px'}}>
                                <ArrowLeftOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} style={{ fontSize: 22, alignContent: 'center',marginRight : '5%' }} />
                                <Title level={3} style={{ fontSize: 22, alignContent: 'center',fontWeight: 'bold', marginBottom: '0px'}}>Mes Commandes</Title>
                            </div>

                            <Menu className="custom-menu" style={{ marginBottom: '30px' }} onClick={onClick} selectedKeys={[content]} mode="horizontal" items={items}/>
                            {content === "achats" ? (
                                <Content>
                                    {/*<AchatsClientMobile/>*/}
                                    <h4 style={{ textAlign: 'center', marginBottom: '25px', fontWeight: 'bold' }}>{getMonthYear(new Date())}</h4>
                                    {renderOrders()} {/* Render the orders here */}

                                    {/*<h4 style={{textAlign:'center',marginBottom: '25px', fontWeight: 'bold' }}>mars 2023</h4>*/}
                                    {/*<div key={"1"} style={{padding: '22px', background: '#fff', borderRadius: '8px', marginBottom: '10px', boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)'}}>*/}
                                    {/*    <p style={{textAlign:'center',marginBottom: '25px', fontWeight: 'bold' }}>Achat le mars 2023</p>*/}

                                    {/*    <Row align="middle">*/}
                                    {/*        <Col>*/}
                                    {/*            <Avatar shape="circle" size="large" icon={<UserOutlined  onPointerEnterCapture={undefined}*/}
                                    {/*                                                                     onPointerLeaveCapture={undefined} />} />*/}
                                    {/*        </Col>*/}
                                    {/*        <Col flex="auto" style={{ paddingLeft: '20px' }}>*/}
                                    {/*            <Title level={4} style={{ marginBottom: '0', fontWeight: 'bold' }}>Produuit</Title>*/}
                                    {/*        </Col>*/}
                                    {/*        <Col>*/}
                                    {/*            <RightOutlined style={{ fontSize: '23px', color: '#ccd2dd' }}  onPointerEnterCapture={undefined}*/}
                                    {/*                           onPointerLeaveCapture={undefined} />*/}
                                    {/*        </Col>*/}
                                    {/*    </Row>*/}
                                    {/*</div>*/}

                                </Content>
                            ) : content === "ventes" ? (
                                <Content style={{margin: '24px 16px', padding: 24, minHeight: 280, background: "#FFFFFF"}}>
                                    Vous n'etes pas vendeur
                                </Content>
                            ) : null
                            }

                        </Content>
                    </Layout>
            ) : (
                <div>
                    <div style={{margin: '15px'}}>
                        <h1 style={{fontSize: '24px'}}>Mes Commandes</h1>
                        {/*<p style={{marginTop: '15px', fontSize: '16px'}}>Lorem ipsum dolor sit amet, consectetur.</p>*/}
                    </div>
                    <div style={{
                        marginTop: '20px',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #FFFFFF',
                        borderRadius: '5px',
                        width: '95%',
                        margin: '20px auto'
                    }}>
                        <div style={{padding: '20px'}}>
                            <Row>
                                <Col flex={2}>
                                    <Space.Compact style={{width: '95%'}}>
                                        <Input name='search' placeholder="Rechercher" style={{width: '100%'}}/>
                                        <Button type="primary" style={{
                                            color: colorHeaderText, backgroundColor:
                                            colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                                        }}><SearchOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Button>
                                    </Space.Compact>
                                </Col>
                                <Col flex={2}>
                                    <Select
                                        showSearch
                                        placeholder="Statut"
                                        optionFilterProp="children"
                                        style={{width: '95%'}}
                                        onChange={onChange}
                                        onSearch={onSearch}
                                        // filterOption={(input, option) =>
                                        //     (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        // }
                                        // options={[
                                        //     {
                                        //         value: '1',
                                        //         label: 'Statut 1',
                                        //         name: 'status1'
                                        //     },
                                        //     {
                                        //         value: '2',
                                        //         label: 'Statut 2',
                                        //         name: 'status2'
                                        //     },
                                        //     {
                                        //         value: '3',
                                        //         label: 'Statut 3',
                                        //         name: 'status3'
                                        //     },
                                        // ]}
                                    />
                                </Col>
                                {/*<Col flex={1} style={{justifyContent: 'end', marginRight: '20px', display: 'flex'}}>*/}
                                {/*    <Button style={{backgroundColor: colorHearderSearch, color: colorHeaderText}}>*/}
                                {/*        Exporter*/}
                                {/*    </Button>*/}
                                {/*</Col>*/}
                                {/*<Col flex={1} style={{display: 'contents'}}>*/}
                                {/*    <Button style={{backgroundColor: colorProductBtn, color: colorHeaderText}}>*/}
                                {/*        Nouvelle Commande*/}
                                {/*    </Button>*/}
                                {/*</Col>*/}
                            </Row>
                        </div>
                        <div style={{marginTop: '10px'}}>
                            <style>
                                {`
                          .custom-table tbody {
                            height: 20px;
                            overflow-y: auto;
                          }
                          
                          /* Remove default padding on the table and related elements */
                          .custom-table .ant-table-thead tr th,
                          .custom-table .ant-table-tbody tr td {
                            padding: 5px;
                          }
                        `}
                            </style>
                            <Table
                                dataSource={dataSource.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)}
                                columns={columns}
                                bordered
                                pagination={false}
                                className="custom-table"
                                style={{margin: '0 20px'}}
                                scroll={{y: 500}} // Set the desired height here (e.g., 400px)
                            />
                        </div>
                        <div style={{margin: '20px'}}>
                            <style>
                                {`
                          .customPaginationStyle {
                             display: flex;
                             justify-content: center;
                             align-items: center;
                          }
                          
                          .customPaginationStyle : hover {
                             background-color : #000000
                          }
                        `}
                            </style>
                            <Pagination
                                total={totalItems}
                                current={currentPage}
                                showSizeChanger
                                onShowSizeChange={handleItemsPerPageChange}
                                onChange={handlePageChange}
                                showTotal={(total, range) => `${range[0]}-${range[1]} sur ${total} produits`}
                                pageSize={itemsPerPage}
                                defaultCurrent={1}
                                style={customPaginationStyle}
                            />
                        </div>
                    </div>
                </div>
            )
            }
        </div>
    );
}