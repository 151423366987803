import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Card,
    Col,
    Row,
    Select,
    Table
} from "antd";
import "@fontsource/montserrat";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/400-italic.css";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {getVendorProduct,} from "../../Products/Products";
import {ArrowDownOutlined, ArrowUpOutlined} from '@ant-design/icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faMoneyBillWave,
    faUsers,
    faBoxOpen,
    faWallet
} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {getVendorOrders} from "../../Order";
import {getUserMangoWallet, User} from "../../User";
import VendorCharts from "../../Misc/VendorCharts";
import AnalyticsCompenent from "../../Analytics";
import ChartComponent from "../../Analytics";
import KPI from "../../Analytics";


interface dataSourceInterface {
    key: string,
    id: string,
    productName: string,
    sku: string,
    stock: string,
    price: number,
    promoPrice: number,
    categories: string,
    date: string,
}


export default function Home() {
    const {t} = useTranslation();
    const colorHeaderText = "#09154C";

    const chartRef1 = useRef(null);
    const chartRef2 = useRef(null);

    // // Récupérer les données depuis l'API (exemple avec fetch)
    // useEffect(() => {
    //     fetch('URL_DE_VOTRE_API')
    //         .then(response => response.json())
    //         .then(data => setChartData1(data))
    //         .catch(error => console.error('Erreur lors de la récupération des données:', error));
    // }, []);
    //
    // // Récupérer les données depuis l'API (exemple avec fetch)
    // useEffect(() => {
    //     fetch('URL_DE_VOTRE_API')
    //         .then(response => response.json())
    //         .then(data => setChartData2(data))
    //         .catch(error => console.error('Erreur lors de la récupération des données:', error));
    // }, []);


    ///  IMPORT DERNIères COMMANDES

    const [dataFetched, setDataFetched] = useState(false);
    const [options, setOptions] = useState<dataSourceInterface[]>([]);
    const [nbOrder, setNbOrder] = useState<number>(0);
    const [totalEarned, setTotalEarned] = useState<string>("");
    const [totalProductsSold, setTotalProductsSold] = useState<number>(0);
    const [userWallet, setUserWallet] = useState<number>(0);
    useEffect(() => {
        const fetchUserInfos = async () => {
            const orders = await getVendorOrders();
            try {
                setNbOrder(orders.length);
                let totalEarnedCalcul = 0
                let totalProductSoldCalcul = 0
                orders.forEach(order => {
                    order.sub_orders.forEach(suborder => {
                        if (suborder.state_id === 4 || suborder.state_id === 3 || suborder.state_id === 2) {
                            totalEarnedCalcul += suborder.product_price_TTC
                            totalProductSoldCalcul += suborder.quantity
                        }
                    })
                })
                setTotalEarned(totalEarnedCalcul.toFixed(2))
                setTotalProductsSold(totalProductSoldCalcul)
                const user_infos = await User()
                const wallet_id = user_infos ? (await user_infos).MangoPayUser[0].wallet_id : undefined;
                if (wallet_id) {
                    setUserWallet(await getUserMangoWallet(wallet_id as unknown as number))
                }
            } catch (error) {
                console.log(error);
            }

            // const products = await getVendorProduct();
            // try {
            //     const mappedOptions = products.map((product) => ({
            //         key: product.product.id as unknown as string,
            //         productName: product.product.product_name,
            //         sku: product.product.product_sku,
            //         date: new Date(product.product.creation_date * 1000).toDateString(),
            //         categories: '',
            //         id: product.product.id as unknown as string,
            //         price: product.product.price,
            //         promoPrice: product.product.price_promo,
            //         stock: product.product.quantity > 0 ? "Stock" : "Hors stock",
            //     } as dataSourceInterface));
            //     setOptions(mappedOptions);
            // } catch (error) {
            //     console.log("No user data found");
            // }

            setDataFetched(true);
        };
        if (!dataFetched) {
            fetchUserInfos();
        }
    }, [dataFetched]);

    const dataSource = options

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Nom du Produit',
            dataIndex: 'productName',
            key: 'productName',
            width: '15%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'état',
            dataIndex: 'paid',
            key: 'paid',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Status',
            dataIndex: 'state',
            key: 'state',
            width: '15%',
            render: (text: any, record: any) => {
                if (record.state === 'delivered') {
                    return <span style={{
                        color: '#00A3FF',
                        backgroundColor: '#F1FAFF',
                        padding: '5px',
                        border: '1px solid #DCF2E6',
                        borderRadius: '5px'
                    }}>{text}</span>;
                } else if (record.state === 'shipping') {
                    return <span style={{
                        color: '#FFA800',
                        backgroundColor: '#FFF4DE',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{text}</span>;
                } else {
                    return <span style={{
                        color: '#F1416C',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{text}</span>;
                }
            },
        },

        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Action',
            key: 'action',
            width: '10%',
            render: (text: any, record: any) => (
                <div style={{display: 'flex'}}>
                    <Button style={{margin: '5px'}} icon={<EditOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}/>
                    <Button style={{margin: '5px'}} icon={<DeleteOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}/>
                </div>
            )
        },
    ];


    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const data_fetched = {
        produits_sorted: [
            {
                designation: "Sikaflex 290 DC PRO - Noir - recharge 600 ml",
                quantity: 145
            },
            {
                designation: "Sikaflex 291 I - Noir - Cartouche 300 Ml",
                quantity: 82
            },
            {
                designation: "Jerrican alimentaire avec robinet - 20 litres",
                quantity: 43
            },
            {
                designation: "Extincteur Abc Avec Mano - 1 Kg",
                quantity: 40
            },
            {
                designation: "Wd-40 - Bidon De 5 Litres + PulvÃ©risateur",
                quantity: 32
            },
            {
                designation: "Wd-40 SpÃ©cialist Lubrifiant Silicone - AÃ©rosol De 400 Ml",
                quantity: 28
            },
            {
                designation: "Nourrice rÃ©servoir plastique BIG-JOE - 12 litres",
                quantity: 26
            },
            {
                designation: "RÃ©novateur Marine Polytrol - 1 L",
                quantity: 18
            },
            {
                designation: "AÃ©rateur Solaire - Ã˜ 217 Mm",
                quantity: 16
            },
            {
                designation: "Joint Colle Ms Polymere - Cartouche De 310 Ml - Noir",
                quantity: 10
            }
        ],
        solde_recuperable_moyen_commande_ht: 34.204175022789364,
        total_commandes_recuperable_ht: 75043.95999999986,
        total_commission_ht: 7581.40999999999,
    };
    // page affiché
    return (
        <div>

            {windowWidth < 768 ? (
                <div style={{margin: '0px 16px', padding: 24, minHeight: 280, background: "#F3F5F6", borderRadius: '15px'}}>
                    <Row gutter={16}>
                        <Col style={{width: '90%', margin: '10px auto'}}>
                            <Card style={{display: 'flex'}}>
                                <Row>
                                    <Col style={{width: '75%'}}>
                                        <Row>
                                            <p style={{
                                                width: '100%', fontSize: '16px', fontFamily: 'Montserrat, sans-serif',
                                                color: colorHeaderText
                                            }}><b>{t('Total de ventes')}</b></p>
                                            <Row>
                                                <Row style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    margin: '10px 0',
                                                    width: '100%'
                                                }}>
                                                    <Col style={{width: 'auto'}}>
                                                        <p style={{
                                                            fontSize: '14px', fontFamily: 'Montserrat, sans-serif',
                                                            color: colorHeaderText
                                                        }}><b>{totalEarned} €</b></p>
                                                    </Col>
                                                    <Col style={{marginLeft: '5px'}}>
                                                        {/*<div style={{*/}
                                                        {/*    backgroundColor: '#50CD89',*/}
                                                        {/*    display: 'flex',*/}
                                                        {/*    padding: '5px',*/}
                                                        {/*    borderRadius: '5px',*/}
                                                        {/*    border: '1px solid #50CD89'*/}
                                                        {/*}}>*/}
                                                        {/*    <ArrowUpOutlined style={{*/}
                                                        {/*        alignItems: 'center',*/}
                                                        {/*        justifyContent: 'center',*/}
                                                        {/*        display: 'flex',*/}
                                                        {/*        fontSize: '12px'*/}
                                                        {/*    }}/>*/}
                                                        {/*    <p style={{*/}
                                                        {/*        marginLeft: '5px',*/}
                                                        {/*        fontSize: '12px',*/}
                                                        {/*        fontFamily: 'Montserrat, sans-serif',*/}
                                                        {/*        color: colorHeaderText*/}
                                                        {/*    }}>2.2 %</p>*/}
                                                        {/*</div>*/}
                                                    </Col>
                                                </Row>
                                                <Row style={{marginTop: '5px'}}>
                                                    <p style={{
                                                        fontSize: '12px', fontFamily: 'Montserrat, sans-serif',
                                                        color: colorHeaderText
                                                    }}>{t('50€ Nouvelles Ventes')}</p>
                                                </Row>
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col style={{
                                        width: '25%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: "flex"
                                    }}>
                                        <FontAwesomeIcon icon={faMoneyBillWave} size={'2x'}/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col style={{width: '90%', margin: '10px auto'}}>
                            <Card>
                                <Row>
                                    <Col style={{width: '75%'}}>
                                        <p style={{
                                            fontSize: '16px', width: '100%', fontFamily: 'Montserrat, sans-serif',
                                            color: colorHeaderText
                                        }}><b>{t('Commandes')}</b></p>
                                        <Row>
                                            <Row style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                margin: '10px 0',
                                                width: '100%'
                                            }}>
                                                <p style={{
                                                    fontSize: '14px', fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}><b>66894</b></p>
                                            </Row>
                                            <Row style={{marginTop: '5px', width: '100%'}}>
                                                <p style={{
                                                    fontSize: '12px', fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}>{t('40 + Nouvelles Commandes')}</p>
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col style={{
                                        width: '25%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: "flex"
                                    }}>
                                        <FontAwesomeIcon icon={faBoxOpen} size={'2x'}/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col style={{width: '90%', margin: '10px auto'}}>
                            <Card>
                                <Row>
                                    <Col style={{width: '75%'}}>
                                        <p style={{
                                            fontSize: '16px', width: '100%', fontFamily: 'Montserrat, sans-serif',
                                            color: colorHeaderText
                                        }}><b>{t('Clients')}</b></p>
                                        <Row>
                                            <Row style={{margin: '10px 0', width: '100%'}}>
                                                <p style={{
                                                    fontSize: '14px', fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}><b>583</b></p>
                                            </Row>
                                            <Row style={{marginTop: '5px', width: '100%'}}>
                                                <p style={{
                                                    fontSize: '12px', fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}>{t('50 + Nouveaux Clients')}</p>
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col style={{
                                        width: '25%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: "flex"
                                    }}>
                                        <FontAwesomeIcon icon={faUsers} size={'2x'}/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col style={{width: '90%', margin: '10px auto'}}>
                            <Card>
                                <Row>
                                    <Col style={{width: '75%'}}>
                                        <p style={{
                                            width: '100%', fontSize: '16px', fontFamily: 'Montserrat, sans-serif',
                                            color: colorHeaderText
                                        }}><b>{t('Solde disponible')}</b></p>
                                        <Row>
                                            <Row style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                margin: '10px 0',
                                                width: '100%'
                                            }}>
                                                <Col style={{width: 'auto'}}>
                                                    <p style={{
                                                        fontSize: '14px', fontFamily: 'Montserrat, sans-serif',
                                                        color: colorHeaderText
                                                    }}><b>360,00 €</b></p>
                                                </Col>
                                                <Col style={{marginLeft: '5px'}}>
                                                    <div style={{
                                                        backgroundColor: '#E95050',
                                                        display: 'flex',
                                                        padding: '5px',
                                                        borderRadius: '5px',
                                                        border: '1px solid #E95050',
                                                        color: 'white'
                                                    }}>
                                                        <ArrowDownOutlined style={{
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            display: 'flex',
                                                            fontSize: '12px'
                                                        }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                                        <p style={{
                                                            marginLeft: '5px',
                                                            fontSize: '12px',
                                                            fontFamily: 'Montserrat, sans-serif',
                                                            color: 'white'
                                                        }}>2.2 %</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row style={{marginTop: '5px'}}>
                                                <p style={{
                                                    fontSize: '12px', fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}>{t('250€ Nouveau Solde')}</p>
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col style={{
                                        width: '25%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: "flex"
                                    }}>
                                        <FontAwesomeIcon icon={faWallet} size={'2x'}/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            ) : windowWidth >= 768 && windowWidth < 1100 ? (
                <div
                    style={{
                        margin: '0px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: "#F3F5F6",
                        borderRadius: '15px'
                    }}>
                    <Row gutter={16}>
                        <Col style={{width: '45%', margin: '10px auto'}}>
                            <Card style={{display: 'flex'}}>
                                <Row>
                                    <Col style={{width: '75%'}}>
                                        <Row>
                                            <p style={{
                                                width: '100%', fontSize: '16px', fontFamily: 'Montserrat, sans-serif',
                                                color: colorHeaderText
                                            }}><b>{t('Total de ventes')}</b></p>
                                            <Row>
                                                <Row style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    margin: '10px 0',
                                                    width: '100%'
                                                }}>
                                                    <Col style={{width: 'auto'}}>
                                                        <p style={{
                                                            fontSize: '14px', fontFamily: 'Montserrat, sans-serif',
                                                            color: colorHeaderText
                                                        }}><b>{totalEarned} €</b></p>
                                                    </Col>
                                                    <Col style={{marginLeft: '5px'}}>
                                                        {/*<div style={{*/}
                                                        {/*    backgroundColor: '#50CD89',*/}
                                                        {/*    display: 'flex',*/}
                                                        {/*    padding: '5px',*/}
                                                        {/*    borderRadius: '5px',*/}
                                                        {/*    border: '1px solid #50CD89'*/}
                                                        {/*}}>*/}
                                                        {/*    <ArrowUpOutlined style={{*/}
                                                        {/*        alignItems: 'center',*/}
                                                        {/*        justifyContent: 'center',*/}
                                                        {/*        display: 'flex',*/}
                                                        {/*        fontSize: '12px'*/}
                                                        {/*    }}/>*/}
                                                        {/*    <p style={{*/}
                                                        {/*        marginLeft: '5px',*/}
                                                        {/*        fontSize: '12px',*/}
                                                        {/*        fontFamily: 'Montserrat, sans-serif',*/}
                                                        {/*        color: colorHeaderText*/}
                                                        {/*    }}>2.2 %</p>*/}
                                                        {/*</div>*/}
                                                    </Col>
                                                </Row>
                                                <Row style={{marginTop: '5px'}}>
                                                    {/*<p style={{*/}
                                                    {/*    fontSize: '12px', fontFamily: 'Montserrat, sans-serif',*/}
                                                    {/*    color: colorHeaderText*/}
                                                    {/*}}>{t('50€ Nouvelles Ventes')}</p>*/}
                                                </Row>
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col style={{
                                        width: '25%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: "flex"
                                    }}>
                                        <FontAwesomeIcon icon={faMoneyBillWave} size={'2x'}/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col style={{width: '45%', margin: '10px auto'}}>
                            <Card>
                                <Row>
                                    <Col style={{width: '75%'}}>
                                        <p style={{
                                            fontSize: '16px', width: '100%', fontFamily: 'Montserrat, sans-serif',
                                            color: colorHeaderText
                                        }}><b>{t('Commandes')}</b></p>
                                        <Row>
                                            <Row style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                margin: '10px 0',
                                                width: '100%'
                                            }}>
                                                <p style={{
                                                    fontSize: '14px', fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}><b>66894</b></p>
                                            </Row>
                                            <Row style={{marginTop: '5px', width: '100%'}}>
                                                <p style={{
                                                    fontSize: '12px', fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}>{t('40 + Nouvelles Commandes')}</p>
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col style={{
                                        width: '25%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: "flex"
                                    }}>
                                        <FontAwesomeIcon icon={faBoxOpen} size={'2x'}/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col style={{width: '45%', margin: '10px auto'}}>
                            <Card>
                                <Row>
                                    <Col style={{width: '75%'}}>
                                        <p style={{
                                            fontSize: '16px', width: '100%', fontFamily: 'Montserrat, sans-serif',
                                            color: colorHeaderText
                                        }}><b>{t('Clients')}</b></p>
                                        <Row>
                                            <Row style={{margin: '10px 0', width: '100%'}}>
                                                <p style={{
                                                    fontSize: '14px', fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}><b>583</b></p>
                                            </Row>
                                            <Row style={{marginTop: '5px', width: '100%'}}>
                                                <p style={{
                                                    fontSize: '12px', fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}>{t('50 + Nouveaux Clients')}</p>
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col style={{
                                        width: '25%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: "flex"
                                    }}>
                                        <FontAwesomeIcon icon={faUsers} size={'2x'}/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col style={{width: '45%', margin: '10px auto'}}>
                            <Card>
                                <Row>
                                    <Col style={{width: '75%'}}>
                                        <p style={{
                                            width: '100%', fontSize: '16px', fontFamily: 'Montserrat, sans-serif',
                                            color: colorHeaderText
                                        }}><b>{t('Solde disponible')}</b></p>
                                        <Row>
                                            <Row style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                margin: '10px 0',
                                                width: '100%'
                                            }}>
                                                <Col style={{width: 'auto'}}>
                                                    <p style={{
                                                        fontSize: '14px', fontFamily: 'Montserrat, sans-serif',
                                                        color: colorHeaderText
                                                    }}><b>360,00 €</b></p>
                                                </Col>
                                                <Col style={{marginLeft: '5px'}}>
                                                    <div style={{
                                                        backgroundColor: '#E95050',
                                                        display: 'flex',
                                                        padding: '5px',
                                                        borderRadius: '5px',
                                                        border: '1px solid #E95050',
                                                        color: 'white'
                                                    }}>
                                                        <ArrowDownOutlined style={{
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            display: 'flex',
                                                            fontSize: '12px'
                                                        }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                                        <p style={{
                                                            marginLeft: '5px',
                                                            fontSize: '12px',
                                                            fontFamily: 'Montserrat, sans-serif',
                                                            color: 'white'
                                                        }}>2.2 %</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row style={{marginTop: '5px'}}>
                                                <p style={{
                                                    fontSize: '12px', fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}>{t('250€ Nouveau Solde')}</p>
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col style={{
                                        width: '25%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: "flex"
                                    }}>
                                        <FontAwesomeIcon icon={faWallet} size={'2x'}/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    {/*    <div style={{display: 'flex'}}>*/}
                    {/*        <Card className={"test12"} style={{*/}
                    {/*            borderRadius: '12px',*/}
                    {/*            width: '95%',*/}
                    {/*            margin: '15px auto',*/}
                    {/*            padding: '15px',*/}
                    {/*            height: '50vh'*/}
                    {/*        }} bordered={false}>*/}
                    {/*            <h2 style={{*/}
                    {/*                fontFamily: 'Montserrat, sans-serif',*/}
                    {/*                color: colorHeaderText,*/}
                    {/*                fontSize: '18px'*/}
                    {/*            }}>{t('Dernières commandes')}</h2>*/}
                    {/*            <div style={{*/}
                    {/*                marginTop: '20px',*/}
                    {/*                backgroundColor: '#FFFFFF',*/}
                    {/*                border: '1px solid #FFFFFF',*/}
                    {/*                borderRadius: '5px'*/}
                    {/*            }}>*/}
                    {/*                <div style={{marginTop: '10px', overflow: "hidden"}}>*/}
                    {/*                    <style>*/}
                    {/*                        {`*/}
                    {/*          .custom-table tbody {*/}
                    {/*            height: 20px;*/}
                    {/*            overflow: hidden;*/}
                    {/*          }*/}
                    {/*        */}
                    {/*          .custom-table .ant-table-thead tr th,*/}
                    {/*          .custom-table .ant-table-tbody tr td {*/}
                    {/*            padding: 5px;*/}
                    {/*          }*/}
                    {/*        `}*/}
                    {/*                    </style>*/}
                    {/*                    <Table*/}
                    {/*                        dataSource={dataSource.slice(-5, dataSource.length)}*/}
                    {/*                        columns={columns}*/}
                    {/*                        bordered*/}
                    {/*                        pagination={false}*/}
                    {/*                        className="custom-table"*/}
                    {/*                        style={{margin: '0 20px', overflow: "hidden"}}*/}
                    {/*                        // Set the desired height here (e.g., 400px)*/}
                    {/*                    />*/}
                    {/*                </div>*/}
                    {/*                <div style={{margin: '20px'}}>*/}
                    {/*                    <style>*/}
                    {/*                        {`*/}
                    {/*          .customPaginationStyle {*/}
                    {/*             display: flex;*/}
                    {/*             justify-content: center;*/}
                    {/*             align-items: center;*/}
                    {/*          }*/}
                    {/*          */}
                    {/*          .customPaginationStyle : hover {*/}
                    {/*             background-color : #000000*/}
                    {/*          }*/}
                    {/*        */}
                    {/*        `}*/}
                    {/*                    </style>*/}

                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </Card>*/}
                    {/*    </div>*/}
                </div>
            ) : (
                <div style={{
                    fontFamily: 'Montserrat, sans-serif',
                    color: colorHeaderText,
                    fontWeight: "500",
                    marginTop: '24px'
                }}>
                    <h1 style={{fontSize: '21px'}}>{t('Accueil')}</h1>
                    <h2 style={{fontSize: '14px', marginTop: '10px'}}>{t('Bienvenue sur votre Dashboard !')}</h2>
                    <div className={"test5"} style={{marginTop: '30px'}}>
                        {windowWidth < 1320 ? (
                            <div>
                                <Row gutter={16}>
                                    <Col style={{width: '25%'}}>
                                        <Card style={{height: '15vw'}}>
                                            <Row>

                                                <Row>
                                                    <p style={{
                                                        fontSize: '1.5vw', fontFamily: 'Montserrat, sans-serif',
                                                        color: colorHeaderText
                                                    }}>{t('Total de ventes')}</p>
                                                    <Row style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        margin: '10px 0'
                                                    }}>
                                                        <Col style={{width: 'auto'}}>
                                                            <p style={{
                                                                fontSize: '1.4vw', fontFamily: 'Montserrat, sans-serif',
                                                                color: colorHeaderText
                                                            }}>{totalEarned} €</p>
                                                        </Col>
                                                        <Col style={{marginLeft: '5px'}}>
                                                            {/*<div style={{*/}
                                                            {/*    backgroundColor: '#50CD89',*/}
                                                            {/*    display: 'flex',*/}
                                                            {/*    padding: '5px',*/}
                                                            {/*    borderRadius: '5px',*/}
                                                            {/*    border: '1px solid #50CD89'*/}
                                                            {/*}}>*/}
                                                            {/*    <ArrowUpOutlined style={{*/}
                                                            {/*        alignItems: 'center',*/}
                                                            {/*        justifyContent: 'center',*/}
                                                            {/*        display: 'flex',*/}
                                                            {/*        fontSize: '1vw'*/}
                                                            {/*    }}/>*/}
                                                            {/*    <p style={{marginLeft: '5px', fontSize: '1vw', fontFamily: 'Montserrat, sans-serif',*/}
                                                            {/*        color: colorHeaderText}}>2.2*/}
                                                            {/*        %</p>*/}
                                                            {/*</div>*/}
                                                        </Col>
                                                    </Row>
                                                    {/*<Row style={{marginTop: '5px'}}>*/}
                                                    {/*    <p style={{fontSize: '1.2vw', fontFamily: 'Montserrat, sans-serif',*/}
                                                    {/*        color: colorHeaderText}}>{t('50€ Nouvelles Ventes')}</p>*/}
                                                    {/*</Row>*/}
                                                </Row>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col style={{width: '25%'}}>
                                        <Card style={{height: '15vw'}}>
                                            <Row>
                                                <p style={{
                                                    fontSize: '1.5vw', fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}>{t('Commandes')}</p>
                                                <Row>
                                                    <Row style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        margin: '10px 0',
                                                        width: '100%'
                                                    }}>
                                                        <p style={{
                                                            fontSize: '1.4vw', fontFamily: 'Montserrat, sans-serif',
                                                            color: colorHeaderText
                                                        }}>66894</p>
                                                    </Row>
                                                    <Row style={{marginTop: '5px', width: '100%'}}>
                                                        <p style={{
                                                            fontSize: '1.2vw', fontFamily: 'Montserrat, sans-serif',
                                                            color: colorHeaderText
                                                        }}>{t('40 + Nouvelles Commandes')}</p>
                                                    </Row>
                                                </Row>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col style={{width: '25%'}}>
                                        <Card style={{height: '15vw'}}>
                                            <Row>
                                                <p style={{
                                                    fontSize: '1.5vw',
                                                    width: '100%',
                                                    fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}>Clients</p>
                                                <Row>
                                                    <Row style={{margin: '10px 0', width: '100%'}}>
                                                        <p style={{
                                                            fontSize: '1.4vw', fontFamily: 'Montserrat, sans-serif',
                                                            color: colorHeaderText
                                                        }}>583</p>
                                                    </Row>
                                                    <Row style={{marginTop: '5px', width: '100%'}}>
                                                        <p style={{
                                                            fontSize: '1.2vw', fontFamily: 'Montserrat, sans-serif',
                                                            color: colorHeaderText
                                                        }}>{t('50 + Nouveaux Clients')}</p>
                                                    </Row>
                                                </Row>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col style={{width: '25%'}}>
                                        <Card style={{height: '15vw'}}>
                                            <Row>
                                                <p style={{
                                                    fontSize: '1.5vw', fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}>{t('Solde disponible')}</p>
                                                <Row>
                                                    <Row style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        margin: '10px 0'
                                                    }}>
                                                        <Col style={{width: 'auto'}}>
                                                            <p style={{
                                                                fontSize: '1.4vw', fontFamily: 'Montserrat, sans-serif',
                                                                color: colorHeaderText
                                                            }}>360,00 €</p>
                                                        </Col>
                                                        <Col style={{marginLeft: '5px'}}>
                                                            <div style={{
                                                                backgroundColor: '#E95050',
                                                                display: 'flex',
                                                                padding: '5px',
                                                                borderRadius: '5px',
                                                                border: '1px solid #E95050',
                                                                color: 'white'
                                                            }}>
                                                                <ArrowDownOutlined style={{
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    display: 'flex',
                                                                    fontSize: '1vw'
                                                                }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                                                <p style={{
                                                                    marginLeft: '5px',
                                                                    fontSize: '1vw',
                                                                    fontFamily: 'Montserrat, sans-serif',
                                                                    color: 'white'
                                                                }}>2.2 %</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{marginTop: '5px'}}>
                                                        <p style={{
                                                            fontSize: '1.2vw', fontFamily: 'Montserrat, sans-serif',
                                                            color: colorHeaderText
                                                        }}>{t('250€ Nouveau Solde')}</p>
                                                    </Row>
                                                </Row>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        ) : (
                            <div>
                                <Row gutter={16}>
                                    <Col style={{width: '25%'}}>
                                        <Card style={{height: '10vw'}}>
                                            <Row>
                                                <p style={{
                                                    fontSize: '1.5vw',
                                                    width: '100%',
                                                    fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}>{t('Total de ventes')}</p>
                                                <Row>
                                                    <Row style={{margin: '10px 0', width: '100%'}}>
                                                        <p style={{
                                                            fontSize: '1.4vw', fontFamily: 'Montserrat, sans-serif',
                                                            color: colorHeaderText
                                                        }}>{totalEarned} €</p>
                                                    </Row>
                                                    <Row style={{marginTop: '5px', width: '100%'}}>
                                                        {/*<p style={{fontSize: '1.2vw', fontFamily: 'Montserrat, sans-serif',*/}
                                                        {/*    color: colorHeaderText}}>{t('50 + Nouveaux Clients')}</p>*/}
                                                    </Row>
                                                </Row>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col style={{width: '25%'}}>
                                        <Card style={{height: '10vw'}}>
                                            <Row>
                                                <p style={{
                                                    fontSize: '1.5vw',
                                                    width: '100%',
                                                    fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}>{t('Commandes reçues')}</p>
                                                <Row>
                                                    <Row style={{margin: '10px 0', width: '100%'}}>
                                                        <p style={{
                                                            fontSize: '1.4vw', fontFamily: 'Montserrat, sans-serif',
                                                            color: colorHeaderText
                                                        }}>{nbOrder}</p>
                                                    </Row>
                                                    <Row style={{marginTop: '5px', width: '100%'}}>
                                                        {/*<p style={{fontSize: '1.2vw', fontFamily: 'Montserrat, sans-serif',*/}
                                                        {/*    color: colorHeaderText}}>{t('50 + Nouveaux Clients')}</p>*/}
                                                    </Row>
                                                </Row>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col style={{width: '25%'}}>
                                        <Card style={{height: '10vw'}}>
                                            <Row>
                                                <p style={{
                                                    fontSize: '1.5vw',
                                                    width: '100%',
                                                    fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}>{t('Produits vendus')}</p>
                                                <Row>
                                                    <Row style={{margin: '10px 0', width: '100%'}}>
                                                        <p style={{
                                                            fontSize: '1.4vw', fontFamily: 'Montserrat, sans-serif',
                                                            color: colorHeaderText
                                                        }}>{totalProductsSold}</p>
                                                    </Row>
                                                    <Row style={{marginTop: '5px', width: '100%'}}>
                                                        {/*<p style={{fontSize: '1.2vw', fontFamily: 'Montserrat, sans-serif',*/}
                                                        {/*    color: colorHeaderText}}>{t('50 + Nouveaux Clients')}</p>*/}
                                                    </Row>
                                                </Row>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col style={{width: '25%'}}>
                                        <Card style={{height: '10vw'}}>
                                            <Row>
                                                <p style={{
                                                    fontSize: '1.5vw',
                                                    width: '100%',
                                                    fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}>{t('Solde disponible')}</p>
                                                <Row>
                                                    <Row style={{margin: '10px 0', width: '100%'}}>
                                                        <p style={{
                                                            fontSize: '1.4vw', fontFamily: 'Montserrat, sans-serif',
                                                            color: colorHeaderText
                                                        }}>{userWallet} €</p>
                                                    </Row>
                                                    <Row style={{marginTop: '5px', width: '100%'}}>
                                                        {/*<p style={{fontSize: '1.2vw', fontFamily: 'Montserrat, sans-serif',*/}
                                                        {/*    color: colorHeaderText}}>{t('50 + Nouveaux Clients')}</p>*/}
                                                    </Row>
                                                </Row>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        )
                        }
                    </div>
                    <div style={{display: 'flex'}}>
                        {/*<Card className={"test10"} style={{*/}
                        {/*    borderRadius: '12px',*/}
                        {/*    width: '65%',*/}
                        {/*    margin: '20px 20px 20px 0',*/}
                        {/*    padding: '15px',*/}
                        {/*    height: '435px'*/}
                        {/*}} bordered={false}>*/}
                        <div>
                            {/*<VendorCharts/>*/}
                        </div>
                        {/*<div style={{display: 'flex'}}>*/}
                        {/*    <h2 style={{*/}
                        {/*        fontFamily: 'Montserrat, sans-serif',*/}
                        {/*        color: colorHeaderText,*/}
                        {/*        fontSize: '18px'*/}
                        {/*    }}>{t('Statistiques des Gains')}</h2>*/}
                        {/*    <Col flex={1} style={{justifyContent: 'end', marginRight: '20px', display: 'flex'}}>*/}
                        {/*        <Button style={{*/}
                        {/*            fontFamily: 'Montserrat, sans-serif',*/}
                        {/*            backgroundColor: '#F3F5F6',*/}
                        {/*            color: colorHeaderText*/}
                        {/*        }}>*/}
                        {/*            {t('Exporter Rapport')}*/}
                        {/*        </Button>*/}
                        {/*    </Col>*/}
                        {/*    <Col flex={1} style={{display: 'contents'}}>*/}
                        {/*        <Select value={"Cette Semaine"}*/}
                        {/*                style={{*/}
                        {/*                    fontFamily: 'Montserrat, sans-serif',*/}
                        {/*                    color: colorHeaderText,*/}
                        {/*                    height: 'fit-content'*/}
                        {/*                }}>*/}
                        {/*        </Select>*/}
                        {/*    </Col>*/}
                        {/*</div>*/}
                        {/*<div style={{height: '350px', justifyContent: 'center', display: 'flex'}}>*/}
                        {/*    <canvas style={{fontFamily: 'Montserrat, sans-serif'}} ref={chartRef1}></canvas>*/}
                        {/*</div>*/}
                        {/*</Card>*/}
                        {/*<Card className={"test11"}*/}
                        {/*      style={{borderRadius: '12px', width: '33%', margin: '20px 0', height: '435px'}}*/}
                        {/*      bordered={false}>*/}
                        {/*    <h2 style={{*/}
                        {/*        fontFamily: 'Montserrat, sans-serif',*/}
                        {/*        color: colorHeaderText,*/}
                        {/*        fontSize: '18px'*/}
                        {/*    }}>Gains</h2>*/}
                        {/*    <canvas style={{fontFamily: 'Montserrat, sans-serif'}} ref={chartRef2}></canvas>*/}
                        {/*</Card>*/}
                        {/*</div>*/}
                        {/*<div style={{display: 'flex'}}>*/}
                        {/*    <Card className={"test12"} style={{*/}
                        {/*        borderRadius: '12px',*/}
                        {/*        width: '100%',*/}
                        {/*        padding: '15px',*/}
                        {/*        height: '50vh'*/}
                        {/*    }} bordered={false}>*/}
                        {/*        <h2 style={{*/}
                        {/*            fontFamily: 'Montserrat, sans-serif',*/}
                        {/*            color: colorHeaderText,*/}
                        {/*            fontSize: '18px'*/}
                        {/*        }}>{t('Dernières commandes')}</h2>*/}
                        {/*        <div style={{*/}
                        {/*            marginTop: '20px',*/}
                        {/*            backgroundColor: '#FFFFFF',*/}
                        {/*            border: '1px solid #FFFFFF',*/}
                        {/*            borderRadius: '5px'*/}
                        {/*        }}>*/}
                        {/*            <div style={{marginTop: '10px', overflow: "hidden"}}>*/}
                        {/*                <style>*/}
                        {/*                    {`*/}
                        {/*      .custom-table tbody {*/}
                        {/*        height: 20px;*/}
                        {/*        overflow: hidden;*/}
                        {/*      }*/}
                        {/*    */}
                        {/*      .custom-table .ant-table-thead tr th,*/}
                        {/*      .custom-table .ant-table-tbody tr td {*/}
                        {/*        padding: 5px;*/}
                        {/*      }*/}
                        {/*    `}*/}
                        {/*                </style>*/}
                        {/*                <Table*/}
                        {/*                    dataSource={dataSource.slice(-5, dataSource.length)}*/}
                        {/*                    columns={columns}*/}
                        {/*                    bordered*/}
                        {/*                    pagination={false}*/}
                        {/*                    className="custom-table"*/}
                        {/*                    style={{margin: '0 20px', overflow: "hidden"}}*/}
                        {/*                    // Set the desired height here (e.g., 400px)*/}
                        {/*                />*/}
                        {/*            </div>*/}
                        {/*            <div style={{margin: '20px'}}>*/}
                        {/*                <style>*/}
                        {/*                    {`*/}
                        {/*      .customPaginationStyle {*/}
                        {/*         display: flex;*/}
                        {/*         justify-content: center;*/}
                        {/*         align-items: center;*/}
                        {/*      }*/}
                        {/*      */}
                        {/*      .customPaginationStyle : hover {*/}
                        {/*         background-color : #000000*/}
                        {/*      }*/}
                        {/*    */}
                        {/*    `}*/}
                        {/*                </style>*/}

                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </Card>*/}
                        {/*</div>*/}
                    </div>
                </div>
            )
            }
        </div>
    );
}

