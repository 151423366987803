import {toast} from 'react-toastify';
import * as XLSX from 'xlsx';
import {getUserById, UserInterface} from "../User";
import {getProductByID} from "../Products/Products";
import fetchAPI from "../Fetch";
import {getUserAddress} from "../Address";

export interface Cart {
    cartProduct: CartProduct[];
    totalHT: number;
    totalTTC: number;
    tva: number;
    expeditionPrice?: []
}
export interface CartPromotion {
    cartProduct: CartProduct[];
    totalHT: number;
    totalTTC: number;
    tva: number;
    expeditionPrice?: [],
    promotion: number | null
}

export interface CartProduct {
    id: number;
    name: string;
    picture: string;
    product: number;
    quantity: number;
    totalPriceHT: number;
    totalPriceTTC: number;
    unitaryPriceHT: number;
    unitaryPriceTTC: number;
    slug: null | string

}

interface AllCarts {
    id: number,
    user_id: number,
    creation_date: number,
    modification_date: number,
    user: UserInterface,
    products: AllCartsProducts[]
}

interface AllCartsProducts {
    id:number,
    product_id: number,
    quantity: number
}
function AddToCart(productId: number, quantity: number) {

    if (!localStorage.getItem('token')) {
        toast.error('Vous devez vous connecter pour effectuer un achat !');

        setTimeout(function () {
            localStorage.setItem('redirect', window.location.href);
            window.location.href = '/login';
        }, 2500);
    } else {

        // toast.success("Article ajouté au panier !");
        // setTimeout(function() {
        //     localStorage.setItem('redirect', window.location.href);
        //     window.location.href = '/cart';
        // }, 2500);

            fetch(`https://${process.env.REACT_APP_API_URL}/cart/product`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    product: productId,
                    quantity: quantity
                }),
            })
                .then(response => response.json())
                .then(data => {
                    // const notify = () => toast("Wow so easy!");
                    // notify()
                    // toast.success("Article ajouté au panier !");
                    // TODO: Show success message
                    console.log("Cart product ADD TO DB with ->",data)


                })
                .catch(error => console.error('Add to cart error:', error));

        return (
            "ok"
            // <button onClick={handleAddToCart}>Add to cart</button>
        );
    }
}

function AddToCartArray(products:CartProduct[]) {

    if (!localStorage.getItem('token')) {
        toast.error('Vous devez vous connecter pour effectuer un achat !');

        setTimeout(function () {
            localStorage.setItem('redirect', window.location.href);
            window.location.href = '/login';
        }, 2500);
    } else {

        fetch(`https://${process.env.REACT_APP_API_URL}/cart/productArray`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                products: products,
            }),
        })
            .then(response => response.json())
            .then(data => {
                // const notify = () => toast("Wow so easy!");
                // notify()
                // toast.success("Article ajouté au panier !");
                // TODO: Show success message
                console.log("Cart product ADD TO DB with ->",data)


            })
            .catch(error => console.error('Add to cart error:', error));

        return (
            "ok"
            // <button onClick={handleAddToCart}>Add to cart</button>
        );
    }
}


async function AddToCartStorage(productId: number, quantity: number) {


    if (quantity>0) {
        const cartData = localStorage.getItem('cartData');
        const cart = cartData ? JSON.parse(cartData) : {
            cartProduct: [],
            totalHT: 0,
            totalTTC: 0,
            tva: 0
        };

        const product = await getProductByID(productId);

        if (!Array.isArray(cart.cartProduct)) {
            console.log("pas de panier , creation d'un nouveau")
            cart.cartProduct = [];
        }

        else {
            let index = 0;
            console.log(cart)
            for (const element of cart.cartProduct) {
                if (element.id === productId) {
                    if (element.quantity+quantity>product.quantity){
                        console.log("produit en rupture de stock vous avez deja tout le stock restant de ce produit dans votre panier")
                        toast.success('Produit en rupture de stock, déjà dans votre panier');
                        return "pas ok";
                    }
                    else {
                        console.log("ajout d'un produit deja present dans le cart")
                        quantity = element.quantity + quantity
                        cart.totalHT -= element.totalPriceHT
                        cart.totalTTC -= element.totalPriceTTC
                        cart.tva -= (element.totalPriceTTC - element.totalPriceHT);
                        cart.cartProduct.splice(index, 1);
                    }

                }
                index += 1;
            }

        }

        let prix = 0;
        if (product.price_promo) {
            prix = product.price_promo;
        } else {
            prix = product.price;
        }
        let price_unitaryHT = 0;
        let price_unitaryTTC = 0;
        let price_totalHT = 0;
        let price_totalTTC = 0;
        let tva = 0;
        console.log("TVA ",product.tva)
        if (product.tva) {
            console.log("ce produit est neuf")
            price_unitaryHT = prix * (1 + product.commission.value/100);
            price_totalHT = (price_unitaryHT * quantity);
            price_unitaryTTC = price_unitaryHT * 1.2
            price_totalTTC = price_totalHT * 1.2;
            tva = price_totalTTC - price_totalHT;
        } else {
            console.log("ce produit n'est pas neuf")
            price_unitaryHT = prix * 1.18;
            price_totalHT = (price_unitaryHT * quantity);
            price_unitaryTTC = price_unitaryHT
            price_totalTTC = price_totalHT
        }

        cart.cartProduct.push({
            id: productId, // 345
            name: product.product_name,
            picture: product.product_image,
            product: productId,
            quantity: quantity,
            totalPriceHT: price_totalHT,
            totalPriceTTC: price_totalTTC,
            totalWeight: product.weight * quantity,
            unitaryPriceHT: price_unitaryHT,
            unitaryPriceTTC: price_unitaryTTC,
            slug: product.slug
        });

        cart.totalHT += price_totalHT
        cart.totalTTC += price_totalTTC
        cart.tva += tva


        localStorage.setItem('cartData', JSON.stringify(cart));

        toast.success('Article ajouté au panier');


        setTimeout(function () {
            localStorage.setItem('redirect', window.location.href);
            window.location.href = '/cart';
        }, 2500);
    }

    return "ok";
}


async function GetCartCount() {
    let cart = await GetCart()
    return cart.cartProduct.length
}

async function GetCartCountStorage() {

    const cartData = localStorage.getItem('cartData');
    const cart = cartData ? JSON.parse(cartData) : {
        cartProduct: [],
        totalHT: 0,
        totalTTC: 0,
        tva: 0
    };
    return cart.cartProduct.length
}


async function GetCart() {
    // if (!localStorage.getItem('token')) {
    //     window.location.href = '/login';
    // }
    return await fetch(`https://${process.env.REACT_APP_API_URL}/cart`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    })
        .then(response => response.json())
        .then(data => {
            return {
                cartProduct: data.response.cart.products as CartProduct[],
                totalHT: data.response.cart.totalHT as number,
                totalTTC: data.response.cart.totalTTC as number,
                tva: data.response.cart.tva as number
            } as Cart

        })
        .catch(error => {
            console.error('Add to cart error:', error);
            return {
                cartProduct: [] as CartProduct[],
                totalHT: 0,
                totalTTC: 0,
                tva: 0
            }
        });


    // return cart;
}

async function CartExists() {
    // if (!localStorage.getItem('token')) {
    //     window.location.href = '/login';
    // }

    return await fetch(`https://${process.env.REACT_APP_API_URL}/cart`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if (data.response && data.response.cart && data.response.cart.products.length > 0) {
                return true; // Le panier existe
            } else {
                return false; // Le panier est vide
            }
        })
        .catch(error => {
            console.error('CartExists error:', error);
            return false; // Erreur de récupération du panier
        });
}



async function GetExpeditionCart(deliveryId: number) {
    // if (!localStorage.getItem('token')) {
    //     window.location.href = '/login';
    // }
    console.log(deliveryId)
    return await fetch(`https://${process.env.REACT_APP_API_URL}/cart`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({deliveryId: deliveryId})
    })
        .then(response => response.json())
        .then(data => {
            return {
                cartProduct: data.response.cart.products as CartProduct[],
                totalHT: data.response.cart.totalHT as number,
                totalTTC: data.response.cart.totalTTC as number,
                tva: data.response.cart.tva as number,
                expeditionPrice: data.response.cart.expeditionPrice.value
            } as Cart

        })
        .catch(error => {
            console.error('Add to cart error:', error);
            return {
                cartProduct: [] as CartProduct[],
                totalHT: 0,
                totalTTC: 0,
                tva: 0
            }
        });


    // return cart;
}

async function getExpeditionId(deliveryId: number, chrono_type:number) {

    const cartData = localStorage.getItem('cartData');
    const cart = cartData ? JSON.parse(cartData) : {
        cartProduct: [],
        totalHT: 0,
        totalTTC: 0,
        tva: 0
    };

    let totalWeight = 0
    for (const product of cart.cartProduct) {
        totalWeight += product.totalWeight
    }
    const cartWeight = totalWeight
    const cartPriceHT = cart.totalHT
    const cartPriceTTC = cart.totalTTC
    // const cartTVA = cart.tva

    if (deliveryId !== undefined) {
        const userAddress= await getUserAddress(deliveryId);
        let user_id = userAddress.user_id
        for (const adresse_type of userAddress.address_type){

            if (adresse_type.id===2){


                const expeditionPrice = await fetchAPI("cart/getWeight", "POST", true, true, {
                    deliveryId: deliveryId,
                    cartWeight: cartWeight,
                    user_Id: user_id,
                    userAddresse: userAddress.zone_id,
                    cartPriceHT : cartPriceHT,
                    cartPriceTTC : cartPriceTTC
                })
                // console.log("expedition price",expeditionPrice.response.expeditionPrice)
                // console.log("Prix livraison = " ,(expeditionPrice.response.expeditionPrice.value[chrono_type].price*1.2).toFixed(2))
                let expeditionId = expeditionPrice.response.expeditionPrice.value[chrono_type].id
                // console.log('expeditionId ',expeditionId)
                return expeditionId
            }
        }
    }

}

async function getExpeditionPrice(deliveryId: number, chrono_type:number) {

    const cartData = localStorage.getItem('cartData');
    const cart = cartData ? JSON.parse(cartData) : {
        cartProduct: [],
        totalHT: 0,
        totalTTC: 0,
        tva: 0
    };

    let totalWeight = 0
    for (const product of cart.cartProduct) {
        totalWeight += product.totalWeight
    }
    const cartWeight = totalWeight
    const cartPriceHT = cart.totalHT
    const cartPriceTTC = cart.totalTTC
    // const cartTVA = cart.tva

    if (deliveryId !== undefined) {
        const userAddress= await getUserAddress(deliveryId);
        let user_id = userAddress.user_id
        for (const adresse_type of userAddress.address_type){

            if (adresse_type.id===2){

                const expeditionPrice = await fetchAPI("cart/getWeight", "POST", true, true, {
                    deliveryId: deliveryId,
                    cartWeight: cartWeight,
                    user_Id: user_id,
                    userAddresse: userAddress.zone_id,
                    cartPriceHT : cartPriceHT,
                    cartPriceTTC : cartPriceTTC
                })

                // console.log("expedition price",expeditionPrice.response.expeditionPrice)
                // console.log("Prix livraison = " ,(expeditionPrice.response.expeditionPrice.value[chrono_type].price*1.2).toFixed(2))

                let expeditionPriceHT = expeditionPrice.response.expeditionPrice.value[chrono_type].price
                console.log('expeditionPriceHT = ',expeditionPriceHT,"€")
                return expeditionPriceHT
            }
        }
    }

}

async function getExpeditionInfos(deliveryId: number, hors_norme:boolean, code_promo:string) {

    const cartData = localStorage.getItem('cartData');
    const cart = cartData ? JSON.parse(cartData) : {
        cartProduct: [],
        totalHT: 0,
        totalTTC: 0,
        tva: 0
    };

    let totalWeight = 0
    for (const product of cart.cartProduct) {
        totalWeight += product.totalWeight
    }
    let cartWeight = totalWeight
    const cartPriceHT = cart.totalHT
    let cartPriceTTC = cart.totalTTC
    const cartTVA = cart.tva
    if(hors_norme) {
        cartWeight=35000
        cartPriceTTC = 140
    }



    if (deliveryId !== undefined) {
        const userAddress= await getUserAddress(deliveryId);
        let user_id = userAddress.user_id
        for (const adresse_type of userAddress.address_type){
            if (adresse_type.id===2){
                console.log("CODE_PROMO 2",code_promo)
                const expeditionInfos = await fetchAPI("cart/getWeight", "POST", true, true, {
                    deliveryId: deliveryId,
                    cartWeight: cartWeight,
                    user_Id: user_id,
                    userAddresse: userAddress.zone_id,
                    cartPriceHT : cartPriceHT,
                    cartPriceTTC : cartPriceTTC,
                    codePromo : code_promo
                })
                console.log("ce que je veux front",expeditionInfos.response.expeditionInfos.expeditions)

                return expeditionInfos.response.expeditionInfos.expeditions
            }
        }
    }

}

async function getTotalWeightCartStorage() {

    const cartData = localStorage.getItem('cartData');
    const cart = cartData ? JSON.parse(cartData) : {
        cartProduct: [],
        totalHT: 0,
        totalTTC: 0,
        tva: 0
    };

    let totalWeight = 0
    for (const product of cart.cartProduct) {
        totalWeight += product.totalWeight
    }
    // le poids est en g mais je le veux en kg
    return totalWeight/1000;

}


export function removeCartProduct(id: number) {
    fetch(`https://${process.env.REACT_APP_API_URL}/cart/product/` + id, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
    })
        .then(response => response.json())
        .then(data => {
            window.location.reload();
            // TODO: Show success message
        })
        .catch(error => console.error('Remove from cart error:', error));

}

function removeCartProductStorage(productId: number) {
    const cartData = localStorage.getItem('cartData');
    const cart = cartData ? JSON.parse(cartData) : {
        cartProduct: [],
        totalHT: 0,
        totalTTC: 0,
        tva: 0
    };

    let index = 0;
    for (const element of cart.cartProduct) {
        if (element.id === productId) {
            console.log("supprimé")
            cart.totalHT -= element.totalPriceHT
            cart.totalTTC -= element.totalPriceTTC
            cart.tva -= (element.totalPriceTTC - element.totalPriceHT);
            cart.cartProduct.splice(index, 1);

        }
        index += 1;
    }

    localStorage.setItem('cartData', JSON.stringify(cart));


    toast.success('Produit supprimé du panier');
    setTimeout(function () {
        localStorage.setItem('redirect', window.location.href);
        window.location.href = '/cart';
    }, 2500);


}

function updateCartProduct(id: number, quantity: number) {
    fetch(`https://${process.env.REACT_APP_API_URL}/cart/product/${id}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            quantity: quantity
        }),
    })
        .then(response => response.json())
        .then(data => {
            if (quantity === 0) {
                window.location.reload();
            }
            // data.response

            // TODO: Show success message
        })
        .catch(error => console.error('Clear cart error:', error));

}

function ClearCart() {

    fetch(`https://${process.env.REACT_APP_API_URL}/cart/clear`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({}),
    })
        .then(response => response.json())
        .then(data => {
            window.location.reload();
            // TODO: Show success message
        })
        .catch(error => console.error('Clear cart error:', error));
}

async function DeleteCart() {
    const response = await fetchAPI("cart/all", "DELETE", true, true)
    return response.status
}

async function getAllCarts() {
    const carts = await fetchAPI("cart/all", "GET", false, true)

    await createExcel(carts.response as AllCarts[]);

}
async function createExcel(carts: AllCarts[]) {
    const ws_data = [
        ['Cart ID', 'User ID', 'User Email', 'User Firstname', 'User Lastname', 'Creation Date', 'Modification Date', 'Product ID', 'Product Name', 'Vendor Name', 'Product Quantity']
    ];

    for (const cart of carts) {
        for (const product of cart.products) {
            const product_infos = await getProductByID(product.product_id)
            const vendor_infos = await getUserById(product_infos.vendor_id)
            ws_data.push([
                cart.id as unknown as string,
                cart.user_id as unknown as string,
                cart.user.user_email,
                cart.user.firstname,
                cart.user.lastname,
                new Date(cart.creation_date * 1000).toISOString(),
                new Date(cart.modification_date * 1000).toISOString(),
                product.product_id as unknown as string,
                product_infos.product_name,
                // vendor_infos.,
                product.quantity as unknown as string
            ]);
        }
    }

    // Créez une feuille de travail à partir des données
    const ws = XLSX.utils.aoa_to_sheet(ws_data);

    // Créez un classeur
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Carts');

    // Écrivez le fichier
    XLSX.writeFile(wb, 'Carts.xlsx');
}

function ClearCartStorage() {

    localStorage.removeItem('cartData');
    toast.success('Panier vidé avec succès');
    setTimeout(function () {
        localStorage.setItem('redirect', window.location.href);
        window.location.href = '/cart';
    }, 2500);
}


export {GetCart, ClearCart, AddToCart,AddToCartArray, updateCartProduct, GetCartCount, GetExpeditionCart,AddToCartStorage,removeCartProductStorage,ClearCartStorage,GetCartCountStorage,getExpeditionId,getExpeditionPrice,getAllCarts,DeleteCart,CartExists,getTotalWeightCartStorage,getExpeditionInfos};
