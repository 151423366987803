import React, {useEffect, useState} from 'react';
import type {MenuProps} from 'antd';
import {Button, Menu} from 'antd';
import EditProfile from "../Profile/EditProfile";
import {Content} from "antd/es/layout/layout";
import Password from "../Profile/Password";
import Company from "../Profile/Company";
import CloseAccount from "../Profile/CloseAccount";
import Address from "../Profile/Adress";
import {LogoutOutlined, UserOutlined} from "@ant-design/icons";


export default function BecomeVendor() {

    const [content, setCurrent] = useState('profile');

    const colorHeaderText = "#09154C"
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{color: colorHeaderText}}>
            {windowWidth < 1100 ? (
                <div
                    style={{
                        margin: '0px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: "#F3F5F6",
                        borderRadius: '15px',
                    }}>
                    <h1 style={{fontSize: '24px'}}>Devenir un vendeur</h1>

                    <LogoutOutlined style={{
                        backgroundColor: colorHeaderText,
                        border: '1px solid' + colorHeaderText,
                        borderRadius: '15px',
                        padding: '10px',
                        height: '100%',
                        width: '100%',
                        color: 'white'
                    }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>

                </div>
            ) : (
                <div>
                    <h1 style={{fontSize: '24px'}}>Devenir un vendeur</h1>
                    <h2 style={{fontSize: '24px', textAlign: 'center'}}>Je suis un vendeur : </h2>
                    <div style={{textAlign: 'center'}}>
                        <Button style={{backgroundColor: "#808080", height: 300, fontSize: '24px', color: 'white'}}
                                onClick={() => {
                                    let url = 'https://beta.captainchercheur.com/users/' + localStorage.getItem('user_id') + '/roles'

                                    let options = {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjY3Yzc1ZTQxYzU4ZjRhNDE5YzJlYTJhMDA0YmI1OTE3IiwiaWF0IjoxNjkzMzgzODg2fQ.Ur7eHISyHcQun3ZlOY0sU7K0fhrQfwFswGfM61wc4C0'
                                        },
                                        body: '{"role":"vendor_private"}'
                                    };
                                    fetch(url, options)
                                        .then(res => res.json())
                                        .then(json => console.log(json))
                                        .then(() => {
                                            localStorage.setItem("role", "vendor")
                                            setTimeout(() => {
                                                window.location.reload()
                                            }, 1000);

                                        })
                                        .catch(err => console.error('error:' + err));

                                }}>
                            <UserOutlined style={{
                                fontSize: '40px',
                                color: 'white'
                            }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                            <br></br>
                            Vendeur Particulier
                        </Button>
                        <Button style={{backgroundColor: "#808080", height: 300, fontSize: '24px', color: 'white'}}
                                onClick={() => {
                                    let url = 'https://beta.captainchercheur.com/users/' + localStorage.getItem('user_id') + '/roles';

                                    let options = {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjY3Yzc1ZTQxYzU4ZjRhNDE5YzJlYTJhMDA0YmI1OTE3IiwiaWF0IjoxNjkzMzgzODg2fQ.Ur7eHISyHcQun3ZlOY0sU7K0fhrQfwFswGfM61wc4C0'
                                        },
                                        body: '{"role":"vendor_professional"}'
                                    };
                                    fetch(url, options)
                                        .then(res => res.json())
                                        .then(json => console.log(json))
                                        .catch(err => console.error('error:' + err));
                                    localStorage.setItem("role", "vendor")
                                    setTimeout(() => {
                                        window.location.reload()
                                    }, 1000);
                                }}>
                            <UserOutlined style={{
                                fontSize: '40px',
                                color: 'white'
                            }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                            <br></br>
                            Vendeur Professionnel
                        </Button>
                    </div>
                </div>
            )
            }
        </div>
    )
}