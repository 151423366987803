import {Button, Col, Form, FormInstance, Input, Row, Select} from "antd";
import React, {useEffect, useState} from "react";
import {AddressType, User, UserAddress, UserInterface} from "../../../User";
import {createShippingAddress, modifyAddress, deleteAddress} from "../../../Address";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface OptionsInterface {
    value: number,
    label: string
}

type RequiredMark = boolean;

export default function ShippingAddress() {

    const colorInfoHeaderTable = "#F3F5F6"
    const colorInfoBorderTable = "#EAEAEA"
    const colorInfoText = "#09154C"
    const colorInfoPayBtn = "#7ED957"

    const [userInfos, setUserInfos] = useState({} as UserInterface);
    const [dataFetched, setDataFetched] = useState(false);
    const [options, setOptions] = useState<OptionsInterface[]>();

    const [shippingInfos, setShippingInfos] = useState<UserAddress>({
        id: 0,
        address: '',
        additional_address: '',
        city: '',
        country: 'france',
        country_code: '',
        zipcode: '',
        phone_number: '',
        company_id: null,
        address_type: [{
            id: 3,
            address_type: "Shipping",
        }] as AddressType[],
        zone_id: 0,
        zone: '',
        company: '',
        region : '',
        firstname : '',
        lastname : '',
        relais : null
    });

    const onChangeShipping = (value_selected: string) => {
        // console.log(`selected ${value}`);
        const selectedUserAddress = userInfos.user_addresses.find(
            (address) => address.id === parseInt(value_selected)
        );
        if (selectedUserAddress) {
            console.log("Selected User ShippingAddress:", selectedUserAddress);
            form.setFieldsValue({
                ...selectedUserAddress,
                // firstname: userInfos.firstname,
                // lastname: userInfos.lastname,
                phone: selectedUserAddress.phone_number,
                // email: userInfos.user_email,
                region: selectedUserAddress.region,
                id : selectedUserAddress.id
            });
            let shipping = document.getElementById("shipping");
            if (shipping){
                shipping.innerText="Modifier cette adresse d'expédition"
                shipping.title="edit"
            }
            let delete_shipping = document.getElementById("delete");
            if (delete_shipping){
                delete_shipping.style.display="";
            }

        }

    };

    // const onChangeShipping = (value_selected: string) => {
    //     // console.log(`selected ${value}`);
    //     const selectedUserAddress = userInfos.user_addresses.find(
    //         (address) => address.id === parseInt(value_selected)
    //     );
    //     if (selectedUserAddress) {
    //         console.log("Selected User BillingAddress:", selectedUserAddress);
    //         setShippingInfos(prevState => {
    //
    //             return {
    //                 zipcode: selectedUserAddress.zipcode,
    //                 phone_number: selectedUserAddress.phone_number,
    //                 address: selectedUserAddress.address,
    //                 country: selectedUserAddress.country,
    //                 city: selectedUserAddress.city,
    //             } as UserAddress
    //         })
    //     }
    //
    // };

    async function delete_Address(forme: FormInstance) {

        try {
            let address_id = forme.getFieldValue("id");
            const response = await deleteAddress(address_id);
            if (response) {
                toast.success("Adresse supprimée avec succès !");
                setTimeout(function () {
                    window.location.href = '/dashboard';
                }, 2000);
            } else {
                toast.error(" Il y a eu un problème lors de la suppression de votre adresse");
                setTimeout(function () {
                    window.location.reload();
                }, 2000);

            }
        } catch (error) {
            console.error('Une erreur s\'est produite lors de la suppression de l\'adresse :', error);
        }
    }

    const onSearch = (value: string) => {
        console.log('search:', value);
    };

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    const [form] = Form.useForm();

    const [requiredMark, setRequiredMarkType] = useState<RequiredMark>(true);

    const onRequiredTypeChange = ({requiredMarkValue}: { requiredMarkValue: RequiredMark }) => {
        setRequiredMarkType(requiredMarkValue);
    }

    const config_firstname = {
        rules: [{type: 'string' as const, required: true, message: 'Veuillez entrer votre prénom'}],
    }
    const config_lastname = {
        rules: [{type: 'string' as const, required: true, message: 'Veuillez entrer votre nom'}],
    }
    const config_address = {
        rules: [{type: 'string' as const, required: true, message: 'Veuillez entrer votre adresse'}],
    }
    const config_country = {
        rules: [{type: 'string' as const, required: true, message: 'Veuillez choisir un pays'}],
    }
    const config_city = {
        rules: [{type: 'string' as const, required: true, message: 'Veuillez choisir une ville'}],
    }
    const config_zipcode = {
        rules: [{type: 'string' as const, required: true, message: 'Veuillez entrer votre code postal'}],
    }
    const config_phone_number = {
        rules: [{type: 'string' as const, required: true, message: 'Veuillez entrer votre numéro de téléphone'}],
    }
    const config_region = {
        rules: [{type: 'string' as const, required: true, message: 'Veuillez entrer une région'}],
    }


    const onFinish = async (values: any) => {
        let shipping = document.getElementById("shipping");
        if (shipping){
            if( shipping.title==="edit"){
                try {
                    let address_id= values.id;
                    const response = await modifyAddress(values,address_id);
                    if (response) {
                        toast.success("Adresse modifiée avec succès !");
                        setTimeout(function() {
                            window.location.href = '/dashboard';
                        }, 2000);
                    } else {
                        toast.error(" Il y a eu un problème lors de la modification de votre adresse");
                        setTimeout(function() {
                            window.location.reload();
                        }, 2000);

                    }
                } catch (error) {
                    console.error('Une erreur s\'est produite lors de la création de l\'adresse :', error);
                }
            }
            if( shipping.title==="create"){
                try {
                    const response = await createShippingAddress(values);
                    if (response.id) {
                        toast.success("Adresse ajoutée avec succès !");
                        setTimeout(function() {
                            window.location.href = '/dashboard';
                        }, 2000);
                    } else {
                        toast.error(" Il y a eu un problème lors de la création de votre adresse");
                        setTimeout(function() {
                            window.location.reload();
                        }, 2000);

                    }
                } catch (error) {
                    console.error('Une erreur s\'est produite lors de la création de l\'adresse :', error);
                }
            }
        }

    };

    useEffect(() => {
        const fetchUserInfos = async () => {
            try {
                const data = await User();
                if (data) {
                    setUserInfos(data);
                    setDataFetched(true);
                    const filteredAddresses = data.user_addresses.filter((address) => address.address_type[0].id === 3);

                    const mappedOptions = filteredAddresses.map((address) => ({
                        value: address.id,
                        label: address.address + ' ' + address.zipcode
                    }));

                    setOptions(mappedOptions);
                    form.setFieldsValue({
                        email: data.user_email,
                    })

                }
            } catch (error) {
                console.log("No user data found");
            }
        };

        if (!dataFetched) {
            fetchUserInfos();
        }
    }, [dataFetched]);

    return (
        <div>
            <style>
                {`
                .ant-row.ant-form-item-row.css-dev-only-do-not-override-txh9fw{
                display : contents !important ;
                }      
                :where(.css-dev-only-do-not-override-txh9fw).ant-form-item .ant-form-item-label >label::after {
                content : "" !important ;     
               `}
            </style>
            {windowWidth < 768 ? (
                <div>
                    <ToastContainer />
                    <Row>
                        <Col flex={3} style={{justifyContent: 'center', display: 'flex'}}>
                            <div style={{width: '90%', color: colorInfoText}}>
                                <div style={{width: '100%'}}>
                                    <Form onFinish={onFinish}
                                          initialValues={{remember: true,requiredMarkValue: requiredMark}}
                                          onValuesChange={onRequiredTypeChange}
                                          requiredMark={requiredMark}
                                          form={form}>
                                        <div>
                                            <Row style={{marginBottom: '20px'}}>
                                                <p style={{marginBottom: '10px'}}>Sélectionner ou Ajouter une adresse d'expédition</p>
                                                <Select
                                                    showSearch
                                                    placeholder="Sélectionner une adresse d'expédition"
                                                    optionFilterProp="children"
                                                    style={{width: '100%'}}
                                                    onChange={onChangeShipping}
                                                    onSearch={onSearch}
                                                    options={options}
                                                />
                                            </Row>
                                            {/*<Form.Item name="id" style={{display: "none"}}/>*/}
                                            <Row style={{marginBottom: '20px'}}>
                                                <Col flex={1} style={{marginRight: '10px'}}>
                                                    {/*<p style={{width: '100%', marginBottom: '10px'}}>Prénom</p>*/}
                                                    <Form.Item name="firstname"  label="Prénom" {...config_firstname}
                                                               tooltip="Ce champ est requis">
                                                        <Input placeholder="Prénom" style={{width: '100%', height: '35px'}} name='firstname' />
                                                    </Form.Item>
                                                </Col>

                                                <Col flex={1}>
                                                    {/*<p style={{width: '100%', marginBottom: '10px'}}>Nom</p>*/}
                                                    <Form.Item name="lastname"  label="Nom de famille" {...config_lastname}
                                                               tooltip="Ce champ est requis">
                                                        <Input placeholder="Nom" style={{width: '100%', height: '35px'}} name='lastname'/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '20px'}}>
                                                <Col flex={1}>
                                                    {/*<p style={{width: '100%', marginBottom: '10px'}}>Adresse (Numéro et Rue)</p>*/}
                                                    <Form.Item name="address" {...config_address} tooltip="Ce champ est requis" label="Adresse (Numéro et Rue)"
                                                               style={{marginBottom: '10px', width: '95%'}}>
                                                        <Input placeholder="Adresse"
                                                               style={{width: '100%', height: '35px'}}
                                                               value={shippingInfos.address}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col flex={1}>
                                                    <p style={{width: '100%', marginBottom: '10px'}}>Complément d'adresse (optionnel)</p>
                                                    <Form.Item name="additional_address"
                                                               style={{marginBottom: '10px', width: '95%'}}>
                                                        <Input placeholder="Apt, suite, unité, nom de l'entreprise (facultatif)"
                                                               style={{width: '100%', height: '35px'}}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '20px'}}>
                                                {/*<p style={{width: '100%', marginBottom: '10px'}}>Pays</p>*/}
                                                <Form.Item name="country"  {...config_country} label="Pays"
                                                           style={{marginBottom: '10px', width: '95%'}}  tooltip="Ce champ est requis">
                                                    <Input placeholder="Pays"
                                                           style={{width: '100%', height: '35px'}}
                                                    />
                                                </Form.Item>
                                                {/*<Select*/}
                                                {/*    showSearch*/}
                                                {/*    placeholder="Sélectionner"*/}
                                                {/*    optionFilterProp="children"*/}
                                                {/*    style={{width: '100%'}}*/}
                                                {/*    // onChange={onChangeShipping}*/}
                                                {/*    onSearch={onSearch}*/}
                                                {/*    value={shippingInfos.country}*/}
                                                {/*    filterOption={(input, option) =>*/}
                                                {/*        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())*/}
                                                {/*    }*/}
                                                {/*    options={[*/}
                                                {/*        {*/}
                                                {/*            value: 'france',*/}
                                                {/*            label: 'France',*/}
                                                {/*            name: 'france'*/}
                                                {/*        },*/}
                                                {/*        {*/}
                                                {/*            value: 'germany',*/}
                                                {/*            label: 'Allemagne',*/}
                                                {/*            name: 'germany'*/}
                                                {/*        },*/}
                                                {/*        {*/}
                                                {/*            value: 'spain',*/}
                                                {/*            label: 'Espagne',*/}
                                                {/*            name: 'spain'*/}
                                                {/*        },*/}
                                                {/*    ]}*/}
                                                {/*/>*/}

                                            </Row>


                                            <Row style={{marginBottom: '20px'}}>
                                                <Col flex={1}>
                                                    {/*<p style={{width: '100%', marginBottom: '10px'}}>Ville</p>*/}
                                                    <Form.Item name="city"  {...config_city} tooltip="Ce champ est requis" label="Ville"
                                                               style={{marginBottom: '10px', width: '95%'}}>
                                                        <Input placeholder="Ville"
                                                               style={{width: '100%', height: '35px'}}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col flex={1}>
                                                    {/*<p style={{width: '100%', marginBottom: '10px'}}>Code Postal</p>*/}
                                                    <Form.Item name="zipcode"  {...config_zipcode} label="Code Postal"  tooltip="Ce champ est requis"
                                                               style={{marginBottom: '10px', width: '95%'}}>
                                                        <Input placeholder="Code Postal"
                                                               style={{width: '100%', height: '35px'}}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '20px'}}>
                                                <Col flex={1}>
                                                    {/*<p style={{width: '100%', marginBottom: '10px'}}>Téléphone</p>*/}
                                                    <Form.Item name="phone"  label="Téléphone"  tooltip="Ce champ est requis" {...config_phone_number}
                                                               style={{marginBottom: '10px', width: '95%'}}>
                                                        <Input placeholder="Téléphone"
                                                               style={{width: '100%', height: '35px'}}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col flex={1}>
                                                    {/*<p style={{width: '100%', marginBottom: '10px'}}>Région</p>*/}
                                                    <Form.Item name="region"  label="Région"  {...config_region} tooltip="Ce champ est requis"
                                                               style={{marginBottom: '10px', width: '95%'}}>
                                                        <Input placeholder="Région"
                                                               style={{width: '100%', height: '35px'}}
                                                        />
                                                    </Form.Item>

                                                    {/*<p style={{width: '100%', marginBottom: '10px'}}>Email</p>*/}
                                                    <Form.Item name="email"
                                                               style={{display:'none',marginBottom: '10px', width: '95%'}}>
                                                        <Input placeholder="Email"
                                                               style={{width: '100%', height: '35px'}}
                                                               value={userInfos.user_email}
                                                               defaultValue={userInfos.user_email} disabled={true}/>

                                                    </Form.Item>
                                                    <Form.Item name="id"
                                                               style={{display:'none',marginBottom: '10px', width: '95%'}}>
                                                        <Input
                                                            style={{width: '100%', height: '35px'}}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row style={{justifyContent: 'end'}}>

                                                <Button style={{
                                                    backgroundColor: colorInfoPayBtn,
                                                    height: '35px'
                                                }}   htmlType="submit" title={"create"} id={"shipping"}>Ajouter cette adresse d'expédition</Button>

                                                <Button style={{
                                                    backgroundColor: colorInfoPayBtn,
                                                    height: '35px',
                                                    display: 'none',
                                                    marginLeft:'20px'
                                                }} onClick={() => {
                                                    delete_Address(form)
                                                }}  id={"delete"}>Supprimer cette adresse d'expédition</Button>

                                            </Row>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            ) : (
                <div>
                    <ToastContainer />
                    <Row>
                        <Col flex={3} style={{justifyContent: 'center', display: 'flex'}}>
                            <div style={{width: '90%', color: colorInfoText}}>
                                <div style={{width: '100%'}}>
                                    <Form onFinish={onFinish}
                                          initialValues={{remember: true,requiredMarkValue: requiredMark}}
                                          onValuesChange={onRequiredTypeChange}
                                          requiredMark={requiredMark}
                                          form={form}>
                                        <div>
                                            <Row style={{marginBottom: '20px'}}>
                                                <p style={{marginBottom: '10px'}}>Sélectionner ou Ajouter une adresse d'expédition</p>
                                                <Select
                                                    showSearch
                                                    placeholder="Sélectionner une adresse d'expédition"
                                                    optionFilterProp="children"
                                                    style={{width: '100%'}}
                                                    onChange={onChangeShipping}
                                                    onSearch={onSearch}
                                                    options={options}
                                                />
                                            </Row>
                                            {/*<Form.Item name="id" style={{display: "none"}}/>*/}
                                            <Row style={{marginBottom: '20px'}}>
                                                <Col flex={1} style={{marginRight: '10px'}}>
                                                    {/*<p style={{width: '100%', marginBottom: '10px'}}>Prénom</p>*/}
                                                    <Form.Item name="firstname"  label="Prénom" {...config_firstname}
                                                               tooltip="Ce champ est requis">
                                                        <Input placeholder="Prénom" style={{width: '100%', height: '35px'}} name='firstname' />
                                                    </Form.Item>
                                                </Col>

                                                <Col flex={1}>
                                                    {/*<p style={{width: '100%', marginBottom: '10px'}}>Nom</p>*/}
                                                    <Form.Item name="lastname"  label="Nom de famille" {...config_lastname}
                                                               tooltip="Ce champ est requis">
                                                        <Input placeholder="Nom" style={{width: '100%', height: '35px'}} name='lastname'/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '20px'}}>
                                                <Col flex={1}>
                                                    {/*<p style={{width: '100%', marginBottom: '10px'}}>Adresse (Numéro et Rue)</p>*/}
                                                    <Form.Item name="address" {...config_address} tooltip="Ce champ est requis" label="Adresse (Numéro et Rue)"
                                                               style={{marginBottom: '10px', width: '95%'}}>
                                                        <Input placeholder="Adresse"
                                                               style={{width: '100%', height: '35px'}}
                                                               value={shippingInfos.address}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col flex={1}>
                                                    <p style={{width: '100%', marginBottom: '10px'}}>Complément d'adresse (optionnel)</p>
                                                    <Form.Item name="additional_address"
                                                               style={{marginBottom: '10px', width: '95%'}}>
                                                        <Input placeholder="Apt, suite, unité, nom de l'entreprise (facultatif)"
                                                               style={{width: '100%', height: '35px'}}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '20px'}}>
                                                {/*<p style={{width: '100%', marginBottom: '10px'}}>Pays</p>*/}
                                                <Form.Item name="country"  {...config_country} label="Pays"
                                                           style={{marginBottom: '10px', width: '95%'}}  tooltip="Ce champ est requis">
                                                    <Input placeholder="Pays"
                                                           style={{width: '100%', height: '35px'}}
                                                    />
                                                </Form.Item>
                                                {/*<Select*/}
                                                {/*    showSearch*/}
                                                {/*    placeholder="Sélectionner"*/}
                                                {/*    optionFilterProp="children"*/}
                                                {/*    style={{width: '100%'}}*/}
                                                {/*    // onChange={onChangeShipping}*/}
                                                {/*    onSearch={onSearch}*/}
                                                {/*    value={shippingInfos.country}*/}
                                                {/*    filterOption={(input, option) =>*/}
                                                {/*        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())*/}
                                                {/*    }*/}
                                                {/*    options={[*/}
                                                {/*        {*/}
                                                {/*            value: 'france',*/}
                                                {/*            label: 'France',*/}
                                                {/*            name: 'france'*/}
                                                {/*        },*/}
                                                {/*        {*/}
                                                {/*            value: 'germany',*/}
                                                {/*            label: 'Allemagne',*/}
                                                {/*            name: 'germany'*/}
                                                {/*        },*/}
                                                {/*        {*/}
                                                {/*            value: 'spain',*/}
                                                {/*            label: 'Espagne',*/}
                                                {/*            name: 'spain'*/}
                                                {/*        },*/}
                                                {/*    ]}*/}
                                                {/*/>*/}

                                            </Row>


                                            <Row style={{marginBottom: '20px'}}>
                                                <Col flex={1}>
                                                    {/*<p style={{width: '100%', marginBottom: '10px'}}>Ville</p>*/}
                                                    <Form.Item name="city"  {...config_city} tooltip="Ce champ est requis" label="Ville"
                                                               style={{marginBottom: '10px', width: '95%'}}>
                                                        <Input placeholder="Ville"
                                                               style={{width: '100%', height: '35px'}}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col flex={1}>
                                                    {/*<p style={{width: '100%', marginBottom: '10px'}}>Code Postal</p>*/}
                                                    <Form.Item name="zipcode"  {...config_zipcode} label="Code Postal"  tooltip="Ce champ est requis"
                                                               style={{marginBottom: '10px', width: '95%'}}>
                                                        <Input placeholder="Code Postal"
                                                               style={{width: '100%', height: '35px'}}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '20px'}}>
                                                <Col flex={1}>
                                                    {/*<p style={{width: '100%', marginBottom: '10px'}}>Téléphone</p>*/}
                                                    <Form.Item name="phone"  label="Téléphone"  tooltip="Ce champ est requis" {...config_phone_number}
                                                               style={{marginBottom: '10px', width: '95%'}}>
                                                        <Input placeholder="Téléphone"
                                                               style={{width: '100%', height: '35px'}}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col flex={1}>
                                                    {/*<p style={{width: '100%', marginBottom: '10px'}}>Région</p>*/}
                                                    <Form.Item name="region"  label="Région"  {...config_region} tooltip="Ce champ est requis"
                                                               style={{marginBottom: '10px', width: '95%'}}>
                                                        <Input placeholder="Région"
                                                               style={{width: '100%', height: '35px'}}
                                                        />
                                                    </Form.Item>

                                                    {/*<p style={{width: '100%', marginBottom: '10px'}}>Email</p>*/}
                                                    <Form.Item name="email"
                                                               style={{display:'none',marginBottom: '10px', width: '95%'}}>
                                                        <Input placeholder="Email"
                                                               style={{width: '100%', height: '35px'}}
                                                               value={userInfos.user_email}
                                                               defaultValue={userInfos.user_email} disabled={true}/>

                                                    </Form.Item>
                                                    <Form.Item name="id"
                                                               style={{display:'none',marginBottom: '10px', width: '95%'}}>
                                                        <Input
                                                            style={{width: '100%', height: '35px'}}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row style={{justifyContent: 'end'}}>

                                                <Button style={{
                                                    backgroundColor: colorInfoPayBtn,
                                                    height: '35px'
                                                }}   htmlType="submit" title={"create"} id={"shipping"}>Ajouter cette adresse d'expédition</Button>

                                                <Button style={{
                                                    backgroundColor: colorInfoPayBtn,
                                                    height: '35px',
                                                    display: 'none',
                                                    marginLeft:'20px'
                                                }} onClick={() => {
                                                    delete_Address(form)
                                                }}  id={"delete"}>Supprimer cette adresse d'expédition</Button>

                                            </Row>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
            }
        </div>
    )
}